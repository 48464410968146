import React, { useState, useEffect } from 'react'
import { ArrowUp } from 'react-feather'
import styled from 'styled-components'

const ButtonWrapper = styled.div<{ isVisible: boolean }>`
  position: fixed;
  bottom: 110px;
  right: ${({ isVisible }) => (isVisible ? '20px' : '-100px')};
  transition: right 0.3s ease-in-out;
`

const Button = styled.button`
  padding: 10px 15px;
  background-color: ${({ theme }) => theme.bg5};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false)

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <ButtonWrapper isVisible={isVisible} onClick={scrollToTop}>
      <Button>
        <ArrowUp />
      </Button>
    </ButtonWrapper>
  )
}

export default ScrollToTopButton
