import React from 'react'
import Logo from '../../assets/svg/logo.svg'
import LogoDark from '../../assets/svg/logo_white.svg'
import { useDarkModeManager } from 'state/user/hooks'
import styled from 'styled-components'
import { ExternalLinkIconWhite, ExternalLinkWhite } from 'theme'
import { Text } from 'rebass'
import { footerLinks, socialMediaLinks } from 'config'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
  @media screen and (max-width: 960px) {
    padding-bottom: 5.5rem;
  }
`
const MainContainer = styled.div`
  display: flex;
  flex: 1;
  width: 80%;
  padding: 2px 20px 2px 20px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  `}
`

const SubContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 15px;
`

const TermsContainer = styled.div`
  width: 100%;
  padding: 0px 20px 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const FooterLineCont = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0 10px 0;
`

const FooterLine = styled.div`
  width: 80%;
  height: 2px;
  background-color: rgba(0, 0, 0, 0.1);
`

const StyledText = styled(Text)`
  color: ${({ theme }) => theme.text1};
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
`

export default function Footer() {
  const { t } = useTranslation()
  const [darkMode] = useDarkModeManager()
  const history = useHistory()

  return (
    <React.Fragment>
      <Container>
        <MainContainer>
          <SubContainer>
            <img width={'60px'} height={'60px'} src={darkMode ? LogoDark : Logo} alt="logo" />
          </SubContainer>
          <SubContainer style={{ justifyContent: 'center' }}>
            {footerLinks.map((link, index) => (
              <ExternalLinkWhite key={index} href={link?.path}>
                {link?.name}
              </ExternalLinkWhite>
            ))}
          </SubContainer>
          <SubContainer style={{ justifyContent: 'end' }}>
            {socialMediaLinks.map((link, index) => {
              const IconComponent = link?.icon
              return <ExternalLinkIconWhite key={index} href={link?.path} icon={<IconComponent />} />
            })}
          </SubContainer>
        </MainContainer>
        <FooterLineCont>
          <FooterLine />
        </FooterLineCont>
        <TermsContainer>
          <StyledText onClick={() => history.push('/agreement')}>{t('toa&pp')}</StyledText>
        </TermsContainer>
      </Container>
    </React.Fragment>
  )
}
