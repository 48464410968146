import React from 'react'
import { ContentTextDetails, Number, NumberedText, Text } from './style'
interface Props {
  number: string
  text: string
}

const ContentTextDetailsSmall = ({ number, text }: Props) => {
  return (
    <ContentTextDetails>
      <NumberedText>
        <Number>{number}</Number>
        <Text>{text}</Text>
      </NumberedText>
    </ContentTextDetails>
  )
}

export default ContentTextDetailsSmall
