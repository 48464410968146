// @ts-nocheck
import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { ThemeContext } from 'styled-components'
import { ChainId, Pair, Token, TokenAmount } from '@uniswap/sdk'
import { Link } from 'react-router-dom'
import { SwapPoolTabs } from '../../components/NavigationTabs'
import { utils } from 'ethers'

// import { useTokenBalancesWithLoadingIndicator } from '../../state/wallet/hooks'
// import { StyledInternalLink, ExternalLink, TYPE, HideSmall } from '../../theme'
import { TYPE, HideSmall } from '../../theme'
import { Text } from 'rebass'
import Card from '../../components/Card'
import { RowBetween, RowFixed } from '../../components/Row'
import { ButtonPrimary } from '../../components/Button'
import { AutoColumn } from '../../components/Column'

import { useActiveWeb3React } from '../../hooks'
// import { usePairs } from '../../data/Reserves'
// import { toV2LiquidityToken, useTrackedTokenPairs } from '../../state/user/hooks'
import { Dots } from '../../components/swap/styleds'
import FullPositionCard from 'components/PositionCard'
import { getMyLiquidity } from 'api/pools'
import { useQuery } from '@tanstack/react-query'
import useTitle from 'hooks/useTitle'

const PageWrapper = styled(AutoColumn)`
  /* max-width: 640px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const TitleRow = styled(RowBetween)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    flex-direction: column-reverse;
  `};
`

const ButtonRow = styled(RowFixed)`
  gap: 8px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
  `};
`

const ResponsiveButtonPrimary = styled(ButtonPrimary)`
  width: fit-content;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 48%;
  `};
`

const ResponsiveButtonSecondary = styled(ButtonPrimary)`
  width: fit-content;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 48%;
  `};
`

const EmptyProposals = styled.div`
  border: 1px solid ${({ theme }) => theme.text4};
  padding: 16px 12px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const WrapperCard = styled.div`
  padding: 20px;
  background-color: ${({ theme }) => theme.bg1};
  border-radius: 32px;
  margin-top: 50px;
  box-shadow: rgba(42, 81, 225, 0.1) 0px 16px 30px;
  width: 40%;
  ${({ theme }) => theme.mediaWidth.laptop`
    width: 90%`};
`
const WrapperCardHeader = styled.div`
  width: 95%;
  padding: 50px;
  background-color: ${({ theme }) => theme.bg1};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 20px;
  margin-top: 50px;
  border-radius: 32px;
  box-shadow: rgba(42, 81, 225, 0.1) 0px 16px 30px;
`

export default function Pool() {
  const { t } = useTranslation()

  const theme = useContext(ThemeContext)
  const { account, chainId } = useActiveWeb3React()

  // fetch the user's balances of all tracked V2 LP tokens
  // const trackedTokenPairs = useTrackedTokenPairs()
  // const tokenPairsWithLiquidityTokens = useMemo(
  //   () => trackedTokenPairs.map(tokens => ({ liquidityToken: toV2LiquidityToken(tokens), tokens })),
  //   [trackedTokenPairs]
  // )
  // const liquidityTokens = useMemo(() => tokenPairsWithLiquidityTokens.map(tpwlt => tpwlt.liquidityToken), [
  //   tokenPairsWithLiquidityTokens
  // ])
  // const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
  //   account ?? undefined,
  //   liquidityTokens
  // )

  // fetch the reserves for all V2 pools in which the user has a balance
  // const liquidityTokensWithBalances = useMemo(
  //   () =>
  //     tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
  //       v2PairsBalances[liquidityToken.address]?.greaterThan('0')
  //     ),
  //   [tokenPairsWithLiquidityTokens, v2PairsBalances]
  // )

  // const v2Pairs = usePairs(liquidityTokensWithBalances.map(({ tokens }) => tokens))
  // const v2IsLoading =
  //   fetchingV2PairBalances || v2Pairs?.length < liquidityTokensWithBalances.length || v2Pairs?.some(V2Pair => !V2Pair)

  // const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => Boolean(v2Pair))

  const { data, isFetched } = useQuery({
    queryKey: ['myLiquidity'],
    queryFn: () => getMyLiquidity(account as string, chainId as number)
  })

  const v2IsLoading = useMemo(() => !isFetched, [isFetched])

  const allV2PairsWithLiquidity: Pair[] = useMemo(() => {
    if (data && data.data && Array.isArray(data.data)) {
      return data?.data?.map((item: any) => {
        const { pair } = item

        const token0 = new Token(chainId as ChainId, pair.token0.id, pair.token0.decimals, pair.token0.symbol)
        const token1 = new Token(chainId as ChainId, pair.token1.id, pair.token1.decimals, pair.token1.symbol)

        return new Pair(
          new TokenAmount(token0, utils.parseEther(pair.reserve0)),
          new TokenAmount(token1, utils.parseEther(pair.reserve1))
        )
      })
    } else return []
  }, [data, chainId])

  console.log(data, allV2PairsWithLiquidity)
  useTitle('Pool')
  // const hasV1Liquidity = false

  return (
    <>
      <PageWrapper>
        <WrapperCardHeader>
          <Text fontWeight={900} fontSize={30}>
            Libex Defi
          </Text>
          <Text fontWeight={500} fontSize={20}>
            {t('liquidityIsLifeblood')}
          </Text>
        </WrapperCardHeader>
        <SwapPoolTabs active={'pool'} />
        <WrapperCard>
          <AutoColumn gap="md" justify="center">
            <AutoColumn gap="md" style={{ width: '100%' }}>
              <TitleRow style={{ marginTop: '1rem' }} padding={'0'}>
                <HideSmall>
                  <TYPE.mediumHeader style={{ marginTop: '0.5rem', justifySelf: 'flex-start' }}>
                    {t('yourLiquidity')}
                  </TYPE.mediumHeader>
                </HideSmall>
                <ButtonRow>
                  <ResponsiveButtonSecondary as={Link} padding="6px 8px" to="/create/ETH">
                    {t('createPair')}
                  </ResponsiveButtonSecondary>
                  <ResponsiveButtonPrimary
                    id="join-pool-button"
                    as={Link}
                    padding="6px 8px"
                    borderRadius="12px"
                    to="/add/ETH"
                  >
                    <Text fontWeight={500} fontSize={16}>
                      {t('addLiquidity')}
                    </Text>
                  </ResponsiveButtonPrimary>
                </ButtonRow>
              </TitleRow>

              {!account ? (
                <Card padding="40px">
                  <TYPE.body color={theme.text3} textAlign="center">
                    {t('connectWalletToViewLiquidity')}
                  </TYPE.body>
                </Card>
              ) : v2IsLoading ? (
                <EmptyProposals>
                  <TYPE.body color={theme.text3} textAlign="center">
                    <Dots>{t('loading')}</Dots>
                  </TYPE.body>
                </EmptyProposals>
              ) : allV2PairsWithLiquidity?.length > 0 ? (
                <>
                  {/* <ButtonSecondary>
                  <RowBetween>
                    <ExternalLink href={'https://uniswap.info/account/' + account}>
                      Account analytics and accrued fees
                    </ExternalLink>
                    <span> ↗</span>
                  </RowBetween>
                </ButtonSecondary> */}
                  {allV2PairsWithLiquidity.map(v2Pair => (
                    <FullPositionCard key={v2Pair.liquidityToken.address} pair={v2Pair} />
                  ))}
                </>
              ) : (
                <EmptyProposals>
                  <TYPE.body color={theme.text3} textAlign="center">
                    {t('noLiquidityFound')}
                  </TYPE.body>
                </EmptyProposals>
              )}

              {/*<AutoColumn justify={'center'} gap="md">
              <Text textAlign="center" fontSize={14} style={{ padding: '.5rem 0 .5rem 0' }}>
                {hasV1Liquidity ? 'Uniswap V1 liquidity found!' : "Don't see a pool you joined?"}{' '}
                <StyledInternalLink id="import-pool-link" to={hasV1Liquidity ? '/migrate/v1' : '/find'}>
                  {hasV1Liquidity ? 'Migrate now.' : 'Import it.'}
                </StyledInternalLink>
              </Text>
            </AutoColumn> */}
            </AutoColumn>
          </AutoColumn>
        </WrapperCard>
      </PageWrapper>
    </>
  )
}
