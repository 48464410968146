import styled from 'styled-components'

export const PoolsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
`

export const PoolContainer = styled.div`
  background-color: ${({ theme }) => theme.bg1};
  padding: 15px;
  border: ${({ theme }) => (theme.currentTheme === 'light' ? '1px solid #F0F0F0' : '1px solid #112A46')};
  box-shadow: 0px 16px 30px rgba(42, 81, 225, 0.1);
  gap: 20px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:first-child {
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
  }
  &:last-child {
    border-bottom-left-radius: 32px;
    border-bottom-right-radius: 32px;
  }
`

export const ItemContainer = styled.div<{ mobileHidden?: boolean }>`
  flex: 1;
  display: flex;
  gap: 20px;
  align-items: center;

  ${({ theme, mobileHidden }) => theme.mediaWidth.laptop`
    display: ${mobileHidden ? 'none' : 'flex'};
  `};
`
export const PoolDetailsContainer = styled.div`
  background-color: ${({ theme }) => theme.bg1};
  padding: 10px;
  display: flex;
  justify-content: space-around;
  ${({ theme }) => theme.mediaWidth.laptop`
  flex-direction: column;
  gap: 20px;
  `};
`

export const PoolDetailsContainerLeft = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  align-items: stretch;
  padding: 20px;
  background-color: ${({ theme }) => theme.bg2};
  border-radius: 5px;
`
export const PoolDetailsContainerRight = styled.div`
  width: 50%;
`

export const IconAndNameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

export const CircleIcon = styled.div`
  border-radius: 32px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background-color: ${({ theme }) => theme.white};
  border: 3px solid ${({ theme }) => theme.yellow2};
`

export const PrecentageContainer = styled.div`
  border-radius: 32px;
  border: 3px solid #f1931f;
  color: #f1931f;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

export const TextContainerWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 40px;
  margin-right: 30px;
`

export const P1 = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  color: ${({ theme }) => theme.text1};
`

export const H1 = styled.h1`
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: ${({ theme }) => theme.text1};
  margin: 0;
`

export const SearchContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  gap: 10px;
  background-color: ${({ theme }) => theme.bg1};
  margin-top: 50px;
  border-radius: 5px;
  ${({ theme }) => theme.mediaWidth.laptop`
    flex-wrap: wrap;
    `};
`

export const SortByContainer = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.bg2};
  padding: 10px;
  min-width: 200px;
`

export const SortByTypeWrapper = styled.div`
  background-color: ${({ theme }) => theme.bg2};
  box-shadow: 0px 33px 40px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  position: relative;
  gap: 20px;
  min-width: 200px;
  :hover {
    cursor: pointer;
  }
`

export const SortByTypeDropdown = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0;
  transform: translateY(100%);
  z-index: 3;
  background-color: ${({ theme }) => theme.bg2};
  box-shadow: 0px 33px 40px rgba(0, 0, 0, 0.03);
  min-width: 200px;
`

export const SortByTypeMenuItem = styled.div`
  min-width: 200px;
  padding: 5px 10px;
  cursor: pointer;
  color: ${({ theme }) => theme.text1};
`

export const P2 = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.text1};
`

export const SearchWrapperWrapper = styled.div`
  width: 80%;
  min-height: 50px;
  background-color: ${({ theme }) => theme.bg2};
  box-shadow: 0px 33px 40px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  :hover {
    cursor: pointer;
  }
  ${({ theme }) => theme.mediaWidth.laptop`
    flex: 1;
    `};
`

export const SearchInput = styled.input`
  flex: 1;
  height: 100%;
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.text1};
  font-size: 16px;
  padding: 0 10px;
  outline: none;
  ::placeholder {
    color: ${({ theme }) => theme.text1};
  }
`

export const WrapperDetailsText = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
`

export const H2 = styled.h1`
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: ${({ theme }) => theme.yellow2};
  margin: 0;
`
