import { ChainId, JSBI, Percent, Token, WETH } from '@uniswap/sdk'
import { AbstractConnector } from '@web3-react/abstract-connector'

import { injected } from '../connectors'
import { ETHERSCAN_PREFIXES } from '../utils'

export const ROUTER_ADDRESS = '0xC1399A02a8e4D1a344d4e366ee64A85A5753a8F9'

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

// configurable: a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

// configurable: main tokens for each chain
export const SZAR = new Token(
  ChainId.LIBEX_TESTNET,
  '0x1207dae7Ac96e25601B6dF6e2b6e80f0e57C20DF',
  18,
  'sZAR',
  'South African Rand'
)
export const BTC = new Token(
  ChainId.LIBEX_TESTNET,
  '0x42a63F62A3f72F279e7dd1524d5bc24ffe4b1aF1',
  18,
  'wBTC',
  'Wrapped Bitcoin'
)

// configurable: weth addresses for each chain
const WETH_ONLY: ChainTokenList = {
  [ChainId.LIBEX_TESTNET]: [WETH[ChainId.LIBEX_TESTNET]]
  // specify other networks here
}

// configurable: list of middleman coins for each chain
// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.LIBEX_TESTNET]: [...WETH_ONLY[ChainId.LIBEX_TESTNET], SZAR, BTC]
}

// configurable:
/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  // [ChainId.MAINNET]: {
  //   [AMPL.address]: [DAI, WETH[ChainId.MAINNET]]
  // }
}

// configurable:
// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.LIBEX_TESTNET]: [...WETH_ONLY[ChainId.LIBEX_TESTNET], SZAR, BTC]
}

// configurable:
// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.LIBEX_TESTNET]: [...WETH_ONLY[ChainId.LIBEX_TESTNET], SZAR, BTC]
}

// configurable:
export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.LIBEX_TESTNET]: [[SZAR, WETH[ChainId.LIBEX_TESTNET]]]
}

export interface WalletInfo {
  connector?: AbstractConnector
  name: string
  iconName: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true
  },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D'
  }
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

// used for rewards deadlines
export const BIG_INT_SECONDS_IN_WEEK = JSBI.BigInt(60 * 60 * 24 * 7)

export const BIG_INT_ZERO = JSBI.BigInt(0)

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), JSBI.BigInt(10000))

export const ZERO_PERCENT = new Percent('0')
export const ONE_HUNDRED_PERCENT = new Percent('1')

// SDN OFAC addresses
export const BLOCKED_ADDRESSES: string[] = []

// configurable:
// Page URL
export const PAGE_URL = 'https://defi.libex.ai/'
export const BACKEND_URL = 'https://backend.defi.libex.ai/'

// configurable:
export const NETWORK_CONFIGS = {
  [ChainId.LIBEX_TESTNET]: {
    chainId: `0x${ChainId.LIBEX_TESTNET.toString(16)}`,
    chainName: 'Libex Testnet',
    rpcUrls: ['https://test-rpc.libex.ai'],
    blockExplorerUrls: [ETHERSCAN_PREFIXES[ChainId.LIBEX_TESTNET]],
    iconUrls: ['https://libex-defi.fra1.cdn.digitaloceanspaces.com/lbx.png'],
    nativeCurrency: {
      name: 'tLibex',
      symbol: 'tLBX',
      decimals: 18
    }
  }
}
