import styled, { keyframes } from 'styled-components'
import { Link } from 'react-router-dom'

export const HomeContainer = styled.div`
  display: flex;
  user-select: none;
  flex-direction: column;
  position: relative;
  align-items: center;
  padding: 0;
  margin: 0;
  width: 100%;
  min-height: fit-content;
  background-color: ${({ theme }) => theme.bg1};
`

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  height: 100%;
  position: absolute;
  z-index: 0;
  width: 100%;
  margin: 0;
  padding: 0;
`

export const LeftHeroLogo = styled.img`
  width: 28%;
  position: absolute;
  top: -350px;
  left: 0;
  aspect-ratio: 0.6;
  margin: 0;
  padding: 0;
  z-index: 1;
  opacity: 0.4;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    top: -300px;`};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    top: -220px;
    width: 35%;`};
  ${({ theme }) => theme.mediaWidth.mobileM`
    top: -180px;
    width: 45%;`};
`
export const RightHeroLogo = styled.img`
  width: 27%;
  position: relative;
  top: 0;
  aspect-ratio: 0.6;
  margin: 0;
  padding: 0;
  opacity: 0.5;
  pointer-events: none;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 35%;
  `};
`

export const HeroText = styled.h1`
  all: unset;
  font-weight: 700;
  font-size: 85px;
  color: ${({ theme }) => theme.text6};
  margin-top: 257px;
  text-align: center;
  width: 70%;
  z-index: 2;
  line-height: 85px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-weight: 600;
    font-size: 65px;
    width: 90%;
    margin-top: 207px;`};
  ${({ theme }) => theme.mediaWidth.mobileM`
    font-weight: 600;
    font-size: 55px;
    width: 90%;
    margin-top: 140px;`};
`

export const HeroSpan = styled.span`
  all: unset;
  color: ${({ theme }) => theme.text6};
  font-weight: 200;
  font-size: 85px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-weight: 200;
    font-size: 65px;`};
  ${({ theme }) => theme.mediaWidth.mobileM`
    font-weight: 200;
    font-size: 55px;`};
`

interface RowProps {
  justify: string
  align?: string
  margin?: string
}
export const Row = styled.div<RowProps>`
  display: flex;
  flex-direction: row;
  align-items: ${props => props.justify || 'center'};
  justify-content: ${props => props.justify};
  margin: ${props => props.margin || '0'};
  width: 100%;
  height: fit-content;
  position: relative;
`
export const DashRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  max-width: 86%;
  height: fit-content;
  position: relative;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 100%;
    align-items: center;
    justify-content: center;`};
`
export const HoneyPotRow = styled.div<RowProps>`
  display: flex;
  flex-direction: row;
  align-items: ${props => props.justify || 'center'};
  justify-content: ${props => props.justify};
  margin: ${props => props.margin || '0'};
  width: 100%;
  height: fit-content;
  position: relative;
  @media (max-width: 882px) {
    flex-direction: column;
  }
`

export const TextDiv = styled.div`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 430px;`};
  ${({ theme }) => theme.mediaWidth.mobileL`
    width: 350px;`};
  ${({ theme }) => theme.mediaWidth.mobileM`
    width: 300px;`};
  ${({ theme }) => theme.mediaWidth.mobileS`
    width: 250px;`};
`
export const HeroButton = styled(Link)<any>`
  all: unset;
  width: 320px;
  height: 64px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 28px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  z-index: 2;
  background-color: ${props => (props.mode === 1 ? '#4F7CFF' : '#F1931F')};
  color: #fff;
  border-radius: 50px;
  &:nth-child(2) {
    margin-left: 24px;
  }

  &:hover {
    opacity: 0.9;
    box-shadow: 0px 0px 5px 0px rgba(238, 235, 235, 0.75);
  }
  &:active {
    opacity: 0.9;
    box-shadow: 0px 0px 5px 0px rgba(238, 235, 235, 0.75);
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 250px;
    height: 50px;
    font-weight: 600;
    font-size: 23px;`};
`

export const RectangleDiv = styled.div`
  width: 100%;
  height: 1600px;
  background-color: ${({ theme }) => theme.bg6};
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: center;
  z-index: 0;
  -webkit-clip-path: polygon(0 0, 50% 23%, 100% 0, 100% 91%, 0 100%);
  clip-path: polygon(0 0, 50% 23%, 100% 0, 100% 91%, 0 100%);
  @media (max-width: 1025px) {
    height: 2100px;
    -webkit-clip-path: polygon(0 0, 50% 13%, 100% 0, 100% 95%, 0 100%);
    clip-path: polygon(0 0, 50% 13%, 100% 0, 100% 95%, 0 100%);
  }
  @media (max-width: 680px) {
    height: 2600px;
    -webkit-clip-path: polygon(0 0, 50% 7%, 100% 0, 100% 95%, 0 100%);
    clip-path: polygon(0 0, 50% 7%, 100% 0, 100% 95%, 0 100%);
  }
  ${({ theme }) => theme.mediaWidth.mobileL`
    -webkit-clip-path: polygon(0 0, 50% 5%, 100% 0, 100% 95%, 0 100%);
    clip-path: polygon(0 0, 50% 5%, 100% 0, 100% 95%, 0 100%);
  `};
`

export const Wrap = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CardRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin: 0;
  position: absolute;
  z-index: 1;
  top: 0;
  width: 100%;
  height: fit-content;
  ${({ theme }) => theme.mediaWidth.laptop`
    flex-wrap: wrap;`};
`

export const Card = styled.div`
  width: 350px;
  height: 390px;
  background-color: #2a51e1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 32px;
  padding: 30px;
  margin: 0 0 20px 0;

  @media (max-width: 1240px) {
    width: 270px;
    &:not(:first-child) {
      margin-left: 20px;
    }
  }
  @media (max-width: 680px) {
    &:not(:first-child) {
      margin-left: 0;
    }
  }
`

const flyY = keyframes`
  0% {
    translate: 0px 0px ;
  }
  
 50% {
    translate: 10px -10px;

  }
  100% {
    translate: 0px 0px ;
  }
`

export const CardImage = styled.img`
  aspect-ratio: 0.87;
  width: 117.79;
`
export const CardImageBee = styled.img`
  aspect-ratio: 0.87;
  width: 117.79;
  animation: ${flyY} 3.2s ease-in-out infinite;
`

export const LockedBeeHex = styled.img`
  width: 460px;
  aspect-ratio: 1/1.01;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 380px;`};
  ${({ theme }) => theme.mediaWidth.mobileL`
    width: 350px;`};
  ${({ theme }) => theme.mediaWidth.mobileM`
    width: 100%;`};
`

interface CardTextProps {
  size?: string
  weight?: string
  margin?: string
}

export const CardText = styled.p<CardTextProps>`
  all: unset;
  font-weight: ${props => props.weight || '900'};
  font-size: ${props => props.size || '48px'};
  line-height: 110%;
  color: #ffffff;
  margin: ${props => props.margin || '0'};
`

interface RectangleTextProps {
  size?: string
  weight?: string
  margin?: string
  width?: string
}

export const RectangleText = styled.p<RectangleTextProps>`
  all: unset;
  font-weight: ${props => props.weight || '700'};
  font-size: ${props => props.size || '60px'};
  margin: ${props => props.margin || '10px 0px 0px 0px'};
  line-height: 65px;
  max-width: ${props => props.width || '705px'};
  text-align: center;
  color: #ffffff;
`
export const RectangleText2 = styled.p<RectangleTextProps>`
  all: unset;
  font-weight: 400;
  font-size: 26px;
  line-height: 140%;
  margin: 32px 0 16px 0;
  max-width: 960px;
  text-align: center;
  color: #ffffff;
`

export const LastTextH = styled.p`
  all: unset;
  font-weight: 700;
  font-size: 60px;
  margin: 10px 0px 0px 0px;
  line-height: 65px;
  max-width: 705px;
  text-align: center;
  color: ${({ theme }) => theme.text7};
`

export const RecButton = styled(Link)<any>`
  all: unset;
  width: 320px;
  height: 64px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 28px;
  z-index: 2;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  background-color: ${props => (props.mode === 1 ? props.theme.primary1 : '#F1931F')};
  color: #fff;
  border-radius: 50px;
  margin: 0 0 15rem 0;
  position: relative;
  &:hover {
    opacity: 0.9;
    box-shadow: 0px 0px 5px 0px rgba(238, 235, 235, 0.75);
  }
  &:active {
    opacity: 0.9;
    box-shadow: 0px 0px 5px 0px rgba(238, 235, 235, 0.75);
  }
`

export const LeftLogo = styled.img`
  width: 200px;
  position: absolute;
  bottom: -6rem;
  aspect-ratio: 0.3;
  left: 0;
  z-index: 0;
  opacity: 0.5;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    bottom: -10rem;`};
  ${({ theme }) => theme.mediaWidth.mobileL`
    width: 150px;`};
`

export const BeeHoneypot = styled.img`
  height: 510px;
  aspect-ratio: 0.92;
  margin: 0 0 0 0;
  ${({ theme }) => theme.mediaWidth.laptop`
    height: 400px;
  `};
  ${({ theme }) => theme.mediaWidth.mobileL`
    height: 360px;
  `};
  ${({ theme }) => theme.mediaWidth.mobileM`
    width: 100%;
  `};
`

export const RightDiv = styled.div`
  width: 35%;
  padding: 0 0 0 70px;
  height: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 0 0 0 7rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 900%;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 0 0 0;
    padding: 0 0 0 0px;
  `};
`
export const LeftDiv = styled.div`
  max-width: 50%;
  height: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 90%;
    align-items: center;
    text-align: center;
  `};
`

export const RightText1 = styled.p`
  all: unset;
  font-weight: 700;
  font-size: 60px;
  max-width: 500px;
  line-height: 65px;
  z-index: 2;
  margin: 120px 0 0 0;
  color: ${({ theme }) => theme.text6};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 90%;
    font-size: 50px;
    margin: 0 0 0 0;
    align-items: center;
    text-align: center;
  `};
  ${({ theme }) => theme.mediaWidth.mobileL`
    font-size: 40px;
  `};
`
export const RightText2 = styled.p`
  all: unset;
  font-weight: 400;
  font-size: 26px;
  max-width: 500px;
  line-height: 140%;
  z-index: 1;
  margin: 0;
  color: ${({ theme }) => theme.text7};
`

export const RightButton = styled(Link)<any>`
  all: unset;
  width: 160px;
  height: 44px;
  border-radius: 34px;
  background-color: ${({ theme }) => theme.primary1};
  color: #fff;
  font-weight: 600;
  font-size: 19.2594px;
  line-height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  &:hover {
    cursor: pointer;
    opacity: 0.9;
    box-shadow: 0px 0px 5px 0px rgba(238, 235, 235, 0.75);
  }
  &:active {
    cursor: pointer;
    opacity: 0.9;
    box-shadow: 0px 0px 5px 0px rgba(238, 235, 235, 0.75);
  }
`
export const RightHoneyButton = styled.button<any>`
  all: unset;
  width: 160px;
  height: 44px;
  border-radius: 34px;
  background-color: #f1931f;
  color: #fff;
  font-weight: 600;
  font-size: 19.2594px;
  line-height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  &:hover {
    cursor: pointer;
    opacity: 0.9;
    box-shadow: 0px 0px 5px 0px rgba(238, 235, 235, 0.75);
  }
  &:active {
    cursor: pointer;
    opacity: 0.9;
    box-shadow: 0px 0px 5px 0px rgba(238, 235, 235, 0.75);
  }
`
export const RightButton2 = styled(Link)<any>`
  all: unset;
  width: 160px;
  height: 44px;
  border-radius: 34px;
  border: 2px solid ${({ theme }) => theme.text6};
  background-color: transparent;
  color: ${({ theme }) => theme.text6};
  font-weight: 600;
  font-size: 19.2594px;
  line-height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 20px;
  &:hover {
    cursor: pointer;
    opacity: 0.9;
    box-shadow: 0px 0px 5px 0px rgba(238, 235, 235, 0.75);
  }
  &:active {
    cursor: pointer;
    opacity: 0.9;
    box-shadow: 0px 0px 5px 0px rgba(238, 235, 235, 0.75);
  }
`

export const ButtonRowR = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0 0 0;
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-content: center;
  `};
`

export const DotWithX = styled.img`
  aspect-ratio: 1.44;
  width: 239px;
  position: absolute;
  top: -40%;
  left: 0%;
  z-index: 0;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`

export const HoneyDash = styled.div`
  width: 45px;
  height: 0px;
  border: 2px solid #f8a137;
  margin: 10px 0 10px 0;
`

export const ParalDiv = styled.div`
  width: 100%;
  height: 1080px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10rem 0 10rem 0;
  background-color: ${({ theme }) => theme.bg6};
  -webkit-clip-path: polygon(0 10%, 100% 0, 100% 90%, 0 100%);
  clip-path: polygon(0 10%, 100% 0, 100% 90%, 0 100%);
  @media (max-width: 1025px) {
    height: 1800px;
    -webkit-clip-path: polygon(0 7%, 100% 0, 100% 93%, 0 100%);
    clip-path: polygon(0 7%, 100% 0, 100% 93%, 0 100%);
  }
  ${({ theme }) => theme.mediaWidth.mobileL`
    height: 1850px;
    -webkit-clip-path: polygon(0 6%, 100% 0, 100% 94%, 0 100%);
    clip-path: polygon(0 6%, 100% 0, 100% 94%, 0 100%);
  `};
  ${({ theme }) => theme.mediaWidth.mobileM`
    height: 1950px;
  `};
  ${({ theme }) => theme.mediaWidth.mobileS`
    height: 2000px;
  `};
`

export const RightHoneyText1 = styled.p`
  all: unset;
  font-weight: 700;
  font-size: 60px;
  width: 500px;
  line-height: 65px;
  margin: 120px 0 0 0;
  color: #fff;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 90%;
    align-items: center;
    font-size: 50px;
    text-align: center;
  `};
`
export const RightHoneyText2 = styled.p`
  all: unset;
  font-weight: 400;
  font-size: 26px;
  width: 500px;
  line-height: 140%;
  margin: 0;
  color: #fff;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 90%;
    align-items: center;
    font-size: 23px;
    text-align: center;
  `};
`

export const RightHoneyButton2 = styled.button<any>`
  all: unset;
  width: 160px;
  height: 44px;
  border-radius: 34px;
  border: 2px solid #fff;
  background-color: transparent;
  color: #fff;
  font-weight: 600;
  font-size: 19.2594px;
  line-height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 20px;
  &:hover {
    cursor: pointer;
    opacity: 0.9;
    box-shadow: 0px 0px 5px 0px rgba(238, 235, 235, 0.75);
  }
  &:active {
    cursor: pointer;
    opacity: 0.9;
    box-shadow: 0px 0px 5px 0px rgba(238, 235, 235, 0.75);
  }
`

export const UpperTableCard = styled.div`
  width: 80%;
  height: 312px;
  padding: 0 61px 0 61px;
  background-color: #ffffff;
  box-shadow: 0px 33px 40px rgba(0, 0, 0, 0.03);
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  z-index: 2;
  top: 5%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 80%;
    height: 472px;
    padding: 0 30px 0 30px;
  `};
`
export const BottomTableCard = styled.div`
  width: 85%;
  height: 312px;
  background-color: #ffffff;
  box-shadow: 0px 33px 40px rgba(0, 0, 0, 0.03);
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  z-index: 2;
  bottom: 5%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 80%;
    height: 562px;
    padding: 0 30px 0 30px;
  `};
  ${({ theme }) => theme.mediaWidth.mobileL`
    width: 80%;
    height: 562px;
    padding: 0 0px 0 0px;
  `};
`

export const HoneyJar = styled.img`
  aspect-ratio: 1.34;
  width: 2.5rem;
`

export const HoneyJarText = styled.p`
  all: unset;
  font-weight: 600;
  font-size: 2rem;
  line-height: 40px;
  margin-left: 1rem;
  color: #232323;
`

export const HoneyJarTable = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: fit-content;
  margin: 2.7rem 0 0 0;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    position: relative;
    left: -5%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.5rem;
  `};
`

export const TableCol = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
  width: 25%;
`

export const TableColContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

export const TableColDivider = styled.div`
  width: 0px;
  height: 142px;
  border: 2px solid #dcdcdc;
  margin-right: 1.8rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    border: 0px solid red;
  `};
`

export const TableRow1 = styled.p`
  all: unset;
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  margin-bottom: 1.5rem;
  color: #667785;
`
export const TableRow2 = styled.p`
  all: unset;
  font-weight: 600;
  font-size: 32px;
  line-height: 2.1rem;
  margin-bottom: 1.5rem;
  color: #2a51e1;
`
export const TableRow3 = styled.p`
  all: unset;
  font-weight: 400;
  font-size: 26px;
  line-height: 2.2rem;
  color: #232323;
`

export const Table2 = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

export const Table2Row = styled.div`
  width: 90%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  &:first-child {
    margin-bottom: 2.7rem;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    justify-content: center;
    &:first-child {
      margin-bottom: 0;
    `};
  @media (max-width: 544px) {
    flex-direction: column;
    justify-content: center;
  }
`

export const Table2ColDivider = styled.div`
  width: 0px;
  height: 4.2rem;
  border: 2px solid #dcdcdc;
  margin-right: 4rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`
export const Table22ColDivider = styled.div`
  width: 100%;
  height: 0;
  border: 2px solid #dcdcdc;
  display: none;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: block;
  `};
`

export const Table2Col = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
  width: 33.33%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-content: center;
    width: 100%;
    &:not(:last-child) {
      margin-bottom: 0.9rem;
    }
  `};
`

export const Table2TextCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

export const Table2Text1 = styled.p`
  all: unset;
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  color: #232323;
  margin-bottom: 8px;
`
export const Table2Text2 = styled.p`
  all: unset;
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.5rem;
  color: #2a51e1;
`

export const MiddleText = styled.p`
  all: unset;
  font-weight: 500;
  font-size: 24px;
  margin: 2rem 0 2rem 0;
  line-height: 140%;
  color: ${({ theme }) => theme.text7};
  text-align: center;
`

export const LastButton = styled.button<any>`
  all: unset;
  width: 320px;
  height: 64px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 28px;
  z-index: 2;
  background-color: ${props => props.theme.primary1};
  color: #fff;
  border-radius: 50px;
  margin: 0 0 15rem 0;
  position: relative;
  &:hover {
    cursor: pointer;
    opacity: 0.9;
    box-shadow: 0px 0px 5px 0px rgba(238, 235, 235, 0.75);
  }
  &:active {
    cursor: pointer;
    opacity: 0.9;
    box-shadow: 0px 0px 5px 0px rgba(238, 235, 235, 0.75);
  }
  ${({ theme }) => theme.mediaWidth.mobileM`
    width: 260px;
    height: 50px;
    font-size: 24px;
  `};
`

export const LastText = styled.p`
  all: unset;
  font-weight: 400;
  width: 50%;
  font-size: 26px;
  line-height: 140%;
  text-align: center;
  color: ${({ theme }) => theme.text7};
`

export const LastDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  margin-top: 10.3rem;
  margin-bottom: 8rem;
`

export const BottomLeftLogo = styled.img`
  aspect-ratio: 0.39;
  width: 280px;
  position: absolute;
  z-index: 1;
  left: 0;
  top: -50%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 200px;
    top: -30%;
  `};
  ${({ theme }) => theme.mediaWidth.mobileL`
    width: 140px;
    top: -20%;
  `};
`

export const BottomRightLogo = styled.img`
  aspect-ratio: 0.5;
  width: 380px;
  right: 0;
  position: absolute;
  bottom: -24%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 200px;
  `};
`
export const RectangleContainer = styled.div`
  width: 100%;
  height: 700px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10rem 0 10rem 0;
  ${({ theme }) => theme.mediaWidth.upToSmall`
   max-height: 1500px;
   margin: 15rem 0 15rem 0;
   `};
`

export const RectangleDiv2 = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  max-height: 900px;

  background-color: ${({ theme }) => theme.bg6};
  -webkit-clip-path: polygon(0 10%, 100% 0, 100% 90%, 0 100%);
  clip-path: polygon(0 10%, 100% 0, 100% 90%, 0 100%);
  @media (max-width: 1025px) {
    height: 1800px;
    -webkit-clip-path: polygon(0 7%, 100% 0, 100% 93%, 0 100%);
    clip-path: polygon(0 7%, 100% 0, 100% 93%, 0 100%);
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
  flex-direction: column;
  `};
`

export const MainRectangleDiv = styled.div`
  width: 90%;
  height: 100%;
  margin: 5rem 0 5rem 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;

  z-index: 1;
  ${({ theme }) => theme.mediaWidth.upToMedium`
  flex-direction: column;
  color: ${({ theme }) => theme.text6};
  width: 100%;
  `};
`
export const RectangleCardImg = styled.img`
  all: unset;
  width: 500px;
  height: 300px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 300px;
  height: 170px
  `};
`
export const IFRAME = styled.iframe`
  min-width: 500px;
  height: 100%;
  z-index: 5;
  border: none;
  scroll-behavior: none;
  ${({ theme }) => theme.mediaWidth.upToMedium`
  max-width: 500px;
  min-height: 500px;
  max-height: 800px;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
  min-width: 90px;
  width: 90%;
  `};
`

export const CardH1 = styled.p`
  all: unset;
  font-weight: 700;
  width: 100%;
  text-align: center;
  font-size: 50px;
  line-height: 65px;
  color: white;
  margin: 0 0 0 0;
  ${({ theme }) => theme.mediaWidth.upToMedium`
  color: ${({ theme }) => theme.text6};`}
  ${({ theme }) => theme.mediaWidth.upToSmall`

    font-size: 50px;
  `};
`

export const CardP1 = styled.p`
  all: unset;
  font-weight: 400;
  width: 100%;
  text-align: center;
  font-size: 26px;
  line-height: 140%;
  color: white;
  margin: 0 0 0 0;
  ${({ theme }) => theme.mediaWidth.upToMedium`
  color: ${({ theme }) => theme.text7};`}
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 22px;
  `};
`

export const Column = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
  width: 90%;
  margin-right: 0rem;
  `};
`
