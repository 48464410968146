import React from 'react'
import { PoolsContainer } from './style'
import PoolCardDetails from '.'
import Loader from 'components/Loader'

const PoolsList = ({ poolData, isLoading }: any) => {
  if (isLoading) {
    return (
      <div
        style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '50px' }}
      >
        <Loader size="100px" />
      </div>
    )
  }

  return (
    <PoolsContainer>
      {poolData?.map((pool: any, index: number) => (
        <PoolCardDetails key={index} pool={pool} />
      ))}
    </PoolsContainer>
  )
}

export default PoolsList
