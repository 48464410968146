import axios from 'axios'
import { STATS_API } from './endpoints'

export const instanceProxy = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    'Access-Control-Allow-Origin': '*'
  }
})

export const getStats = async (network: any) => {
  const { data } = await instanceProxy.get(`${STATS_API}`, {
    params: {
      network: network
    }
  })
  return data
}
