import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import i18n from '../../i18n'
import React from 'react'
import { Globe } from 'react-feather'
import { useMemo, useState, useCallback, useEffect, useRef } from 'react'

const Image = styled.img`
  width: 30px;
  height: 24px;
  border-radius: 0;
  margin-left: 8px;
  ${({ theme }) => theme.mediaWidth.mobileS`
    margin-left: 2px;
  `}
`

const DropDownContainer = styled.div`
  max-width: fit-content;
  position: relative;
  user-select: none;
`
const DropDownHeader = styled.div`
  max-width: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50px;
  position: relative;
  z-index: 100;
  &:hover {
    opacity: 0.9;
    box-shadow: 0px 0px 3px ${props => props.theme.text2};
  }
`

const DropDownListContainer = styled.div`
  position: absolute;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 150%;
  left: -260%;
  width: 200px;
  border-radius: 2rem;
  border: 1px solid ${props => props.theme.bg1};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    left: -260%;
    top: -1000%;
  `}
  ${({ theme }) => theme.mediaWidth.mobileL`
    left: -370%;
  `};
  ${({ theme }) => theme.mediaWidth.mobileM`
    left: -590%;
  `};
  ${({ theme }) => theme.mediaWidth.mobileS`
    left: -600%;
  `};
`

const DropDownList = styled.div`
  padding: 0;
  margin: 0;
  width: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid ${props => props.theme.bg1};
  border-bottom-right-radius: 2rem;
  border-bottom-left-radius: 2rem;
  color: white;
  background-color: ${props => props.theme.bg2};
  font-size: 1.3rem;
  font-weight: 500;
`
const DropDownHead = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  align-items: center;
  background-color: ${props => props.theme.bg2};
  font-weight: 600;
  font-size: 18px;
  color: ${props => props.theme.text7};
  height: 4rem;
  border-bottom: 1px solid ${props => props.theme.bg1};
`

const ListItem = styled.button`
  all: unset;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 88%;
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 0.8rem;
  border-radius: 0.5rem;
  color: ${props => props.theme.text7};
  padding: 0.5rem;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  &:first-child {
    margin-top: 1rem;
  }
  &:last-child {
    margin-bottom: 1rem;
  }
  &:hover {
    background-color: ${({ disabled }) => (disabled ? null : '#eaf8fd')};
    color: ${({ disabled }) => (disabled ? null : '#f8a137')};
  }
`
const ListItemName = styled.p`
  all: unset;
  margin-left: 0.3rem;
`
const LanguageIcon = styled(Globe)`
  width: 25px;
  height: 25px;
  color: ${props => props.theme.text6};
  cursor: pointer;
`

// configurable: add more languages here and in public/locales
const languages = [
  {
    id: 'en',
    name: 'English'
  },
  {
    id: 'zu',
    name: 'Zulu'
  },
  {
    id: 'af',
    name: 'Afrikaans'
  }
]

/**
 * Showing a list of languages to select from locales
 * @dev: locales should be placed in public/locales and languages should be configured inside this component
 */
function LanguageSelector() {
  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(false)

  const toggling = () => setIsOpen(!isOpen)

  const onOptionClicked = useCallback(
    (value: any) => {
      setIsOpen(false)
      i18n.changeLanguage(`${value.id}`)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n.language, i18n]
  )
  const Dialog = useRef<any>(null)
  const Dialogy = useRef<any>(null)

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (Dialog.current && !Dialog?.current?.contains(event.target) && !Dialogy?.current?.contains(event.target)) {
        setIsOpen(false)
      }
    }
    isOpen && document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen])

  const LanguageList = useMemo(
    () =>
      languages.map((item: any, index: number) => (
        <ListItem disabled={item.id === i18n.language} onClick={() => onOptionClicked(item)} key={index}>
          <Image alt={`${item.id}`} src={require(`../../assets/images/languages/${item.id}.png`)} />

          <ListItemName>{item?.name}</ListItemName>
        </ListItem>
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n.language, onOptionClicked]
  )

  return (
    <>
      <DropDownContainer>
        <DropDownHeader ref={Dialog} onClick={toggling}>
          <LanguageIcon />
        </DropDownHeader>
        {isOpen && (
          <DropDownListContainer ref={Dialogy}>
            <DropDownHead>{t('selectLanguage')}</DropDownHead>
            <DropDownList>{LanguageList}</DropDownList>
          </DropDownListContainer>
        )}
      </DropDownContainer>
    </>
  )
}

export default LanguageSelector
