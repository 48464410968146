import { ChainId, CurrencyAmount, JSBI, Token, Trade, WETH, Pair } from '@uniswap/sdk'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ArrowDown } from 'react-feather'
import ReactGA from 'react-ga'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import AddressInputPanel from '../../components/AddressInputPanel'
import { ButtonError, ButtonLight, ButtonPrimary, ButtonConfirmed } from '../../components/Button'
import Card, { GreyCard } from '../../components/Card'
import Column, { AutoColumn } from '../../components/Column'
import ConfirmSwapModal from '../../components/swap/ConfirmSwapModal'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import { SwapPoolTabs } from '../../components/NavigationTabs'
import { AutoRow, RowBetween } from '../../components/Row'
import AdvancedSwapDetailsDropdown from '../../components/swap/AdvancedSwapDetailsDropdown'
import BetterTradeLink, { DefaultVersionLink } from '../../components/swap/BetterTradeLink'
import confirmPriceImpactWithoutFee from '../../components/swap/confirmPriceImpactWithoutFee'
import {
  ArrowWrapper,
  BigText,
  BottomGrouping,
  DivDiv,
  GreenText,
  HeadingLabelLeft,
  HeadingLabelRight,
  HeadingLabels,
  PriceChangeWrapper,
  SmallTextWrapper,
  SwapCallbackError,
  Tab,
  TabMenuTime,
  TextDown,
  TextUp,
  TokensText,
  Wrapper,
  WrapperContainer
} from '../../components/swap/styleds'
import TradePrice from '../../components/swap/TradePrice'
import TokenWarningModal from '../../components/TokenWarningModal'
import ProgressSteps from '../../components/ProgressSteps'
import SwapHeader, { SlippageCont } from '../../components/swap/SwapHeader'

//import { INITIAL_ALLOWED_SLIPPAGE } from '../../constants'
import { useActiveWeb3React } from '../../hooks'
import { useCurrency, useAllTokens } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallbackFromTrade } from '../../hooks/useApproveCallback'
import useENSAddress from '../../hooks/useENSAddress'
import { useSwapCallback } from '../../hooks/useSwapCallback'
import useToggledVersion, { DEFAULT_VERSION, Version } from '../../hooks/useToggledVersion'
import useWrapCallback, { WrapType } from '../../hooks/useWrapCallback'
import { useToggleSettingsMenu, useWalletModalToggle } from '../../state/application/hooks'
import { Field } from '../../state/swap/actions'
import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState
} from '../../state/swap/hooks'
import { useExpertModeManager, useUserSlippageTolerance, useUserSingleHopOnly } from '../../state/user/hooks'
import { LinkStyledButton, TYPE } from '../../theme'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { computeTradePriceBreakdown, warningSeverity } from '../../utils/prices'
import AppBody from '../AppBody'
import { ClickableText } from '../Pool/styleds'
import Loader from '../../components/Loader'
import { useIsTransactionUnsupported } from 'hooks/Trades'
import UnsupportedCurrencyFooter from 'components/swap/UnsupportedCurrencyFooter'
import { isTradeBetter } from 'utils/trades'
import { useTranslation } from 'react-i18next'
import useTitle from 'hooks/useTitle'

import { useWeb3React } from '@web3-react/core'
import { WrappedTokenInfo } from 'state/lists/hooks'
import { Chart } from 'components/Chart/Chart'
import { useGetMethod } from 'hooks/UseFetch'
import { getChartData } from 'api/chartData'

const NETWORK_LABELS: { [chainId in ChainId]?: string } = {
  [ChainId.LIBEX_TESTNET]: 'Libex Testnet'
}

export default function Swap() {
  const { chainId } = useWeb3React()

  const loadedUrlParams = useDefaultsFromURLSearch()
  useTitle('Swap')
  // token warning stuff
  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency(loadedUrlParams?.inputCurrencyId),
    useCurrency(loadedUrlParams?.outputCurrencyId)
  ]
  const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(false)
  const urlLoadedTokens: Token[] = useMemo(
    () => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c instanceof Token) ?? [],
    [loadedInputCurrency, loadedOutputCurrency]
  )
  const handleConfirmTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
  }, [])

  // dismiss warning if all imported tokens are in active lists
  const defaultTokens = useAllTokens()
  const importTokensNotInDefault =
    urlLoadedTokens &&
    urlLoadedTokens.filter((token: Token) => {
      return !Boolean(token.address in defaultTokens)
    })

  const { account } = useActiveWeb3React()
  const theme = useContext(ThemeContext)

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  // for expert mode
  const toggleSettings = useToggleSettingsMenu()
  const [isExpertMode] = useExpertModeManager()

  // get custom setting values for user
  const [allowedSlippage] = useUserSlippageTolerance()
  const { t } = useTranslation()

  // swap state
  const { independentField, typedValue, recipient } = useSwapState()
  const {
    v1Trade,
    v2Trade,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError
  } = useDerivedSwapInfo()
  const { wrapType, execute: onWrap, inputError: wrapInputError } = useWrapCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue
  )
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const { address: recipientAddress } = useENSAddress(recipient)
  const toggledVersion = useToggledVersion()
  const tradesByVersion = {
    [Version.v1]: v1Trade,
    [Version.v2]: v2Trade
  }
  const trade = showWrap ? undefined : tradesByVersion[toggledVersion]
  const defaultTrade = showWrap ? undefined : tradesByVersion[DEFAULT_VERSION]

  const betterTradeLinkV2: Version | undefined =
    toggledVersion === Version.v1 && isTradeBetter(v1Trade, v2Trade) ? Version.v2 : undefined

  const parsedAmounts = showWrap
    ? {
        [Field.INPUT]: parsedAmount,
        [Field.OUTPUT]: parsedAmount
      }
    : {
        [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
        [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount
      }

  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers()
  const isValid = !swapInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput]
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput]
  )

  // modal and loading
  const [{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
    showConfirm: boolean
    tradeToConfirm: Trade | undefined
    attemptingTxn: boolean
    swapErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined
  })

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ''
      : parsedAmounts[dependentField]?.toSignificant(6) ?? ''
  }

  const route = trade?.route
  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  )
  const noRoute = !route

  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallbackFromTrade(trade, allowedSlippage)

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approval, approvalSubmitted])

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(currencyBalances[Field.INPUT])
  const atMaxAmountInput = Boolean(maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput))

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(trade, allowedSlippage, recipient)

  const { priceImpactWithoutFee } = computeTradePriceBreakdown(trade)

  const [singleHopOnly] = useUserSingleHopOnly()

  const handleSwap = useCallback(() => {
    if (priceImpactWithoutFee && !confirmPriceImpactWithoutFee(priceImpactWithoutFee)) {
      return
    }
    if (!swapCallback) {
      return
    }
    setSwapState({ attemptingTxn: true, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: undefined })
    swapCallback()
      .then(hash => {
        setSwapState({ attemptingTxn: false, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: hash })

        ReactGA.event({
          category: 'Swap',
          action:
            recipient === null
              ? 'Swap w/o Send'
              : (recipientAddress ?? recipient) === account
              ? 'Swap w/o Send + recipient'
              : 'Swap w/ Send',
          label: [trade?.inputAmount?.currency?.symbol, trade?.outputAmount?.currency?.symbol, Version.v2].join('/')
        })

        ReactGA.event({
          category: 'Routing',
          action: singleHopOnly ? 'Swap with multihop disabled' : 'Swap with multihop enabled'
        })
      })
      .catch(error => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm,
          swapErrorMessage: error.message,
          txHash: undefined
        })
      })
  }, [
    priceImpactWithoutFee,
    swapCallback,
    tradeToConfirm,
    showConfirm,
    recipient,
    recipientAddress,
    account,
    trade,
    singleHopOnly
  ])

  // errors
  const [showInverted, setShowInverted] = useState<boolean>(false)

  // warnings on slippage
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !swapInputError &&
    (approval === ApprovalState.NOT_APPROVED ||
      approval === ApprovalState.PENDING ||
      (approvalSubmitted && approval === ApprovalState.APPROVED)) &&
    !(priceImpactSeverity > 3 && !isExpertMode)

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({ showConfirm: false, tradeToConfirm, attemptingTxn, swapErrorMessage, txHash })
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash])

  const handleAcceptChanges = useCallback(() => {
    setSwapState({ tradeToConfirm: trade, swapErrorMessage, txHash, attemptingTxn, showConfirm })
  }, [attemptingTxn, showConfirm, swapErrorMessage, trade, txHash])

  const handleInputSelect = useCallback(
    inputCurrency => {
      setApprovalSubmitted(false) // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, inputCurrency)
    },
    [onCurrencySelection]
  )

  const handleMaxInput = useCallback(() => {
    maxAmountInput && onUserInput(Field.INPUT, maxAmountInput.toExact())
  }, [maxAmountInput, onUserInput])

  const handleOutputSelect = useCallback(outputCurrency => onCurrencySelection(Field.OUTPUT, outputCurrency), [
    onCurrencySelection
  ])

  const swapIsUnsupported = useIsTransactionUnsupported(currencies?.INPUT, currencies?.OUTPUT)
  const [pairContract, setPairContract] = useState<any>(undefined)
  const [token0Address, setToken0Address] = useState<string | undefined>(undefined)
  const [activeTab, setActiveTab] = useState<number>(1)
  const handleTabClick = (tabId: number) => {
    if (activeTab !== tabId) {
      setActiveTab(tabId)
    }
  }
  const tabsData = [
    { id: 1, label: '24H' },
    { id: 2, label: '1W' },
    { id: 3, label: '1M' }
    //{ id: 4, label: '1Y' },
  ]

  function dateAndTime(timestamp: number) {
    const output = new Date(timestamp)

    return `${output.toLocaleDateString('en-US')}, ${output.toLocaleTimeString('en-US')}`
  }

  const TimePeriods = {
    1: '24h',
    2: '7d',
    3: '1m',
    4: '1y'
  }

  useEffect(() => {
    let { INPUT, OUTPUT } = currencies
    if (!account) return
    if (!(INPUT && OUTPUT)) return

    if (!(INPUT instanceof WrappedTokenInfo)) {
      INPUT = WETH[chainId as ChainId]
    } else if (!(OUTPUT instanceof WrappedTokenInfo)) {
      OUTPUT = WETH[chainId as ChainId]
    }

    const INPUT_TOKEN = new Token(chainId as ChainId, (INPUT as any).address, INPUT.decimals, INPUT.symbol, INPUT.name)
    const OUTPUT_TOKEN = new Token(
      chainId as ChainId,
      (OUTPUT as any).address,
      OUTPUT.decimals,
      OUTPUT.symbol,
      OUTPUT.name
    )
    setToken0Address(INPUT_TOKEN?.address)

    try {
      const pairAddress = Pair.getAddress(INPUT_TOKEN, OUTPUT_TOKEN)
      setPairContract(pairAddress.toString().toLowerCase())
    } catch {
      // ignore errors, this will fail for wrap and unwrap
    }
  }, [currencies, account, chainId])

  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [fetchedData, setFetchedData] = useState<any>([])
  const { data, refetch, isLoading, error } = useGetMethod(
    'liquidity/getPairs',
    getChartData,
    {
      network: NETWORK_LABELS[chainId as ChainId] === 'Libex Testnet' ? 'testnet' : 'mainnet',
      pairContractAddress: pairContract,
      //@ts-ignore
      timePeriod: TimePeriods[activeTab]
    },
    {
      chainId: chainId
    },
    false
  )

  useEffect(() => {
    refetch()
  }, [pairContract, activeTab, refetch])

  return (
    <>
      <TokenWarningModal
        isOpen={importTokensNotInDefault.length > 0 && !dismissTokenWarning}
        tokens={importTokensNotInDefault}
        onConfirm={handleConfirmTokenWarning}
      />
      <SwapPoolTabs active={'swap'} />
      <WrapperContainer>
        {!error &&
          !(wrapType === WrapType.WRAP || wrapType === WrapType.UNWRAP) &&
          account &&
          !isLoading &&
          Array.isArray(data?.timeseries) &&
          data?.timeseries.length > 0 &&
          currencies?.INPUT &&
          currencies?.OUTPUT && (
            <DivDiv>
              <HeadingLabels
                isShowChart={true}
                style={{
                  ...(Array.isArray(fetchedData) &&
                    fetchedData.length === 0 && {
                      display: 'none'
                    })
                }}
              >
                <HeadingLabelLeft>
                  <BigText>{parseFloat(fetchedData[activeIndex]?.change.toFixed(6))}</BigText>
                  <SmallTextWrapper>
                    <TextUp>{dateAndTime(fetchedData[activeIndex]?.date)}</TextUp>
                    <TextDown>
                      <PriceChangeWrapper>
                        <TokensText
                          style={{
                            lineHeight: '1rem'
                          }}
                        >
                          {currencies.INPUT.name}/{currencies.OUTPUT.name}
                        </TokensText>
                        <GreenText
                          style={{
                            lineHeight: '1rem'
                          }}
                        >
                          {parseFloat(
                            activeIndex === 0
                              ? (fetchedData[activeIndex + 1]?.change - fetchedData[activeIndex]?.change).toFixed(5)
                              : (fetchedData[activeIndex]?.change - fetchedData[activeIndex - 1]?.change).toFixed(5)
                          )}
                          <br />(
                          {parseFloat(
                            activeIndex === 0
                              ? Math.abs(
                                  ((fetchedData[activeIndex + 1]?.change - fetchedData[activeIndex]?.change) /
                                    fetchedData[activeIndex]?.change) *
                                    100
                                ).toFixed(5)
                              : Math.abs(
                                  ((fetchedData[activeIndex]?.change - fetchedData[activeIndex - 1]?.change) /
                                    fetchedData[activeIndex - 1]?.change) *
                                    100
                                ).toFixed(5)
                          )}
                          % )
                        </GreenText>
                      </PriceChangeWrapper>
                    </TextDown>
                  </SmallTextWrapper>
                </HeadingLabelLeft>
                <HeadingLabelRight>
                  <TabMenuTime>
                    {tabsData?.map(tab => (
                      <Tab key={tab.id} isActive={activeTab === tab.id} onClick={() => handleTabClick(tab.id)}>
                        {tab.label}
                      </Tab>
                    ))}
                  </TabMenuTime>
                </HeadingLabelRight>
              </HeadingLabels>
              <Chart
                data={data}
                setFetchedData={setFetchedData}
                isLoading={isLoading}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                timePeriod={activeTab}
                token0Address={token0Address}
                fetchedData={fetchedData}
              />
            </DivDiv>
          )}
        <div style={{ width: '100%', minWidth: '290px', display: 'flex', flexDirection: 'column' }}>
          <AppBody>
            <SwapHeader />
            <Wrapper id="swap-page">
              <ConfirmSwapModal
                isOpen={showConfirm}
                trade={trade}
                originalTrade={tradeToConfirm}
                onAcceptChanges={handleAcceptChanges}
                attemptingTxn={attemptingTxn}
                txHash={txHash}
                recipient={recipient}
                allowedSlippage={allowedSlippage}
                onConfirm={handleSwap}
                swapErrorMessage={swapErrorMessage}
                onDismiss={handleConfirmDismiss}
              />

              <AutoColumn gap={'md'}>
                <CurrencyInputPanel
                  label={
                    independentField === Field.OUTPUT && !showWrap && trade
                      ? `${t('from')} (${t('estimated')})`
                      : `${t('from')}`
                  }
                  value={formattedAmounts[Field.INPUT]}
                  showMaxButton={!atMaxAmountInput}
                  currency={currencies[Field.INPUT]}
                  onUserInput={handleTypeInput}
                  onMax={handleMaxInput}
                  onCurrencySelect={handleInputSelect}
                  otherCurrency={currencies[Field.OUTPUT]}
                  id="swap-currency-input"
                />
                <AutoColumn justify="space-between">
                  <AutoRow
                    justify={isExpertMode ? 'space-between' : 'center'}
                    style={{ padding: '0 1rem', marginTop: '2rem' }}
                  >
                    <ArrowWrapper clickable>
                      <ArrowDown
                        size="16"
                        onClick={() => {
                          setApprovalSubmitted(false) // reset 2 step UI for approvals
                          onSwitchTokens()
                        }}
                        color={currencies[Field.INPUT] && currencies[Field.OUTPUT] ? 'white' : 'white'}
                      />
                    </ArrowWrapper>
                    {recipient === null && !showWrap && isExpertMode ? (
                      <LinkStyledButton id="add-recipient-button" onClick={() => onChangeRecipient('')}>
                        + {t('addSend')} ({t('optional')})
                      </LinkStyledButton>
                    ) : null}
                  </AutoRow>
                </AutoColumn>
                <CurrencyInputPanel
                  value={formattedAmounts[Field.OUTPUT]}
                  onUserInput={handleTypeOutput}
                  label={independentField === Field.INPUT && !showWrap && trade ? 'To (estimated)' : 'To'}
                  showMaxButton={false}
                  currency={currencies[Field.OUTPUT]}
                  onCurrencySelect={handleOutputSelect}
                  otherCurrency={currencies[Field.INPUT]}
                  id="swap-currency-output"
                />
                <SlippageCont>
                  <ClickableText fontWeight={600} fontSize={14} color={theme.text1} onClick={toggleSettings}>
                    {t('slippageTolerance')}
                  </ClickableText>
                  <Text fontWeight={600} fontSize={14} color={theme.text6}>
                    {allowedSlippage / 100}%
                  </Text>
                </SlippageCont>
                {recipient !== null && !showWrap ? (
                  <>
                    <AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
                      <ArrowWrapper clickable={false}>
                        <ArrowDown size="16" color={theme.text2} />
                      </ArrowWrapper>
                      <LinkStyledButton id="remove-recipient-button" onClick={() => onChangeRecipient(null)}>
                        - {t('removeSend')}
                      </LinkStyledButton>
                    </AutoRow>
                    <AddressInputPanel id="recipient" value={recipient} onChange={onChangeRecipient} />
                  </>
                ) : null}
                {showWrap ? null : (
                  <Card padding={showWrap ? '.25rem 1rem 0 1rem' : '0px'} borderRadius={'20px'}>
                    <AutoColumn gap="8px" style={{ padding: '0 16px' }}>
                      {Boolean(trade) && (
                        <RowBetween align="center">
                          <Text fontWeight={500} fontSize={14} color={theme.text2}>
                            {t('price')}
                          </Text>
                          <TradePrice
                            price={trade?.executionPrice}
                            showInverted={showInverted}
                            setShowInverted={setShowInverted}
                          />
                        </RowBetween>
                      )}
                      {/*allowedSlippage !== INITIAL_ALLOWED_SLIPPAGE && (
                    <RowBetween align="center">
                      <ClickableText fontWeight={500} fontSize={14} color={theme.text2} onClick={toggleSettings}>
                        Slippage Tolerance
                      </ClickableText>
                      <ClickableText fontWeight={500} fontSize={14} color={theme.text2} onClick={toggleSettings}>
                        {allowedSlippage / 100}%
                      </ClickableText>
                    </RowBetween>
                  )*/}
                    </AutoColumn>
                  </Card>
                )}
              </AutoColumn>
              <BottomGrouping>
                {swapIsUnsupported ? (
                  <ButtonPrimary disabled={true}>
                    <TYPE.main mb="4px">{t('unsupportedAssets')}</TYPE.main>
                  </ButtonPrimary>
                ) : !account ? (
                  <ButtonLight style={{ color: 'white' }} onClick={toggleWalletModal}>
                    {t('justConnectWallet')}
                  </ButtonLight>
                ) : showWrap ? (
                  <ButtonPrimary disabled={Boolean(wrapInputError)} onClick={onWrap}>
                    {wrapInputError ??
                      (wrapType === WrapType.WRAP ? 'Wrap' : wrapType === WrapType.UNWRAP ? 'Unwrap' : null)}
                  </ButtonPrimary>
                ) : noRoute && userHasSpecifiedInputOutput ? (
                  <GreyCard style={{ textAlign: 'center' }}>
                    <TYPE.main mb="4px">{t('insufficientLiquidityForThisTrade')}</TYPE.main>
                    {singleHopOnly && <TYPE.main mb="4px">{t('enablingMultiHopTrades')}</TYPE.main>}
                  </GreyCard>
                ) : showApproveFlow ? (
                  <RowBetween>
                    <ButtonConfirmed
                      onClick={approveCallback}
                      disabled={approval !== ApprovalState.NOT_APPROVED || approvalSubmitted}
                      width="48%"
                      altDisabledStyle={approval === ApprovalState.PENDING} // show solid button while waiting
                      confirmed={approval === ApprovalState.APPROVED}
                    >
                      {approval === ApprovalState.PENDING ? (
                        <AutoRow gap="6px" justify="center">
                          {t('approving')} <Loader stroke="white" />
                        </AutoRow>
                      ) : approvalSubmitted && approval === ApprovalState.APPROVED ? (
                        `${t('approved')}`
                      ) : (
                        `${t('approve')}` + currencies[Field.INPUT]?.symbol
                      )}
                    </ButtonConfirmed>
                    <ButtonError
                      onClick={() => {
                        if (isExpertMode) {
                          handleSwap()
                        } else {
                          setSwapState({
                            tradeToConfirm: trade,
                            attemptingTxn: false,
                            swapErrorMessage: undefined,
                            showConfirm: true,
                            txHash: undefined
                          })
                        }
                      }}
                      width="48%"
                      id="swap-button"
                      disabled={
                        !isValid || approval !== ApprovalState.APPROVED || (priceImpactSeverity > 3 && !isExpertMode)
                      }
                      error={isValid && priceImpactSeverity > 2}
                    >
                      <Text fontSize={16} fontWeight={500}>
                        {priceImpactSeverity > 3 && !isExpertMode
                          ? `${t('priceImpactHigh')}`
                          : `${t('swap')}${priceImpactSeverity > 2 ? `${t('anyway')}` : ''}`}
                      </Text>
                    </ButtonError>
                  </RowBetween>
                ) : (
                  <ButtonError
                    onClick={() => {
                      if (isExpertMode) {
                        handleSwap()
                      } else {
                        setSwapState({
                          tradeToConfirm: trade,
                          attemptingTxn: false,
                          swapErrorMessage: undefined,
                          showConfirm: true,
                          txHash: undefined
                        })
                      }
                    }}
                    id="swap-button"
                    disabled={!isValid || (priceImpactSeverity > 3 && !isExpertMode) || !!swapCallbackError}
                    error={isValid && priceImpactSeverity > 2 && !swapCallbackError}
                  >
                    <Text fontSize={20} fontWeight={500}>
                      {swapInputError
                        ? swapInputError
                        : priceImpactSeverity > 3 && !isExpertMode
                        ? `${t('priceImpactTooHigh')}`
                        : `${t('swap')}${priceImpactSeverity > 2 ? `${t('anyway')}` : ''}`}
                    </Text>
                  </ButtonError>
                )}
                {showApproveFlow && (
                  <Column style={{ marginTop: '1rem' }}>
                    <ProgressSteps steps={[approval === ApprovalState.APPROVED]} />
                  </Column>
                )}
                {isExpertMode && swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
                {betterTradeLinkV2 && !swapIsUnsupported && toggledVersion === Version.v1 ? (
                  <BetterTradeLink version={betterTradeLinkV2} />
                ) : toggledVersion !== DEFAULT_VERSION && defaultTrade ? (
                  <DefaultVersionLink />
                ) : null}
              </BottomGrouping>
            </Wrapper>
          </AppBody>
          {!swapIsUnsupported ? (
            <AdvancedSwapDetailsDropdown trade={trade} />
          ) : (
            <UnsupportedCurrencyFooter show={swapIsUnsupported} currencies={[currencies.INPUT, currencies.OUTPUT]} />
          )}
        </div>
      </WrapperContainer>
    </>
  )
}
