import React, { useEffect, useState } from 'react'
import { ChartContainer, ChartWrapper } from './style'
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts'

interface Props {
  timePeriod: number
  setActiveIndex: any
  isLoading: boolean
  fetchedData: any
  activeIndex: number
  token0Address: string | undefined
  setFetchedData: any
  data: any
}

/**
 * @dev: Chart component for showing data fetched from node proxy
 */
export const Chart = ({
  token0Address,
  timePeriod,
  setActiveIndex,
  isLoading,
  fetchedData,
  setFetchedData,
  data,
  activeIndex
}: Props) => {
  const TimePeriods = {
    1: '24h',
    2: '7d',
    3: '1m',
    4: '1y'
  }

  function convertSecondsToDate(secs: number) {
    const output = new Date(secs)
    return output.toLocaleDateString('en-US')
  }
  function convertSecondsToHours(secs: number) {
    const output = new Date(secs)
    return output.toLocaleTimeString('en-US')
  }

  const [payload, setPayload] = useState<any>(0)
  useEffect(() => {
    const refined: any = []
    let obj = {}
    if (data !== undefined && data.timeseries !== undefined) {
      const reserves =
        token0Address?.toString().toLowerCase() === data?.token0.address.toString().toLowerCase() ? [0, 1] : [1, 0]
      for (let i = 0; i < data?.timeseries.length; i++) {
        reserves[0] === 0
          ? (obj = {
              name:
                //@ts-ignore
                TimePeriods[timePeriod] === '24h'
                  ? convertSecondsToHours(data?.timeseries[i].date)
                  : convertSecondsToDate(data?.timeseries[i].date),

              change: data?.timeseries[i].reserve0 / data?.timeseries[i].reserve1,
              a: data?.timeseries[i].reserve0,
              b: data?.timeseries[i].reserve1,
              date: data?.timeseries[i].date
            })
          : (obj = {
              name:
                //@ts-ignore
                TimePeriods[timePeriod] === '24h'
                  ? convertSecondsToHours(data?.timeseries[i].date)
                  : convertSecondsToDate(data?.timeseries[i].date),

              change: data?.timeseries[i].reserve1 / data?.timeseries[i].reserve0,
              a: data?.timeseries[i].reserve1,
              b: data?.timeseries[i].reserve0,
              date: data?.timeseries[i].date
            })
        refined.push(obj)
        //@ts-ignore
        // if (data?.timeseries.length > 25) switch (TimePeriods[timePeriod]) {
        //   case '24h':
        //     break;
        //   case '7d':
        //     i = i + 6;
        //     break;
        //   case '1m':
        //     i = i + 29;
        //     break;
        //   case '1y':
        //     i = i + 369;
        //     break;
        //   default:
        //     break;
        // }
      }
      setFetchedData(refined)
      setPayload(refined.length - 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, timePeriod, token0Address])
  useEffect(() => {
    if (activeIndex !== payload) setActiveIndex(payload)
  }, [payload, activeIndex, setActiveIndex])
  return (
    <>
      <ChartWrapper isOpenChart={true}>
        <ChartContainer>
          {isLoading ? (
            <style>
              {`
.dot-falling {
position: relative;
left: -9999px;
width: 10px;
height: 10px;
border-radius: 5px;
background-color: #9880ff;
color: #9880ff;
box-shadow: 9999px 0 0 0 #9880ff;
animation: dot-falling 1s infinite linear;
animation-delay: 0.1s;
}
.dot-falling::before, .dot-falling::after {
content: "";
display: inline-block;
position: absolute;
top: 0;
}
.dot-falling::before {
width: 10px;
height: 10px;
border-radius: 5px;
background-color: #9880ff;
color: #9880ff;
animation: dot-falling-before 1s infinite linear;
animation-delay: 0s;
}
.dot-falling::after {
width: 10px;
height: 10px;
border-radius: 5px;
background-color: #9880ff;
color: #9880ff;
animation: dot-falling-after 1s infinite linear;
animation-delay: 0.2s;
}

@keyframes dot-falling {
0% {
box-shadow: 9999px -15px 0 0 rgba(152, 128, 255, 0);
}
25%, 50%, 75% {
box-shadow: 9999px 0 0 0 #9880ff;
}
100% {
box-shadow: 9999px 15px 0 0 rgba(152, 128, 255, 0);
}
}
@keyframes dot-falling-before {
0% {
box-shadow: 9984px -15px 0 0 rgba(152, 128, 255, 0);
}
25%, 50%, 75% {
box-shadow: 9984px 0 0 0 #9880ff;
}
100% {
box-shadow: 9984px 15px 0 0 rgba(152, 128, 255, 0);
}
}
@keyframes dot-falling-after {
0% {
box-shadow: 10014px -15px 0 0 rgba(152, 128, 255, 0);
}
25%, 50%, 75% {
box-shadow: 10014px 0 0 0 #9880ff;
}
100% {
box-shadow: 10014px 15px 0 0 rgba(152, 128, 255, 0);
}
}
      `}
            </style>
          ) : (
            <ResponsiveContainer minWidth={300} minHeight={400} width="100%" height="100%">
              <AreaChart
                width={290}
                height={400}
                data={fetchedData}
                onMouseLeave={() => {
                  if (setActiveIndex) setPayload(fetchedData.length - 1)
                }}
              >
                <defs>
                  <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={'#16d59c'} stopOpacity={0.5} />
                    <stop offset="100%" stopColor={'#16d59c67'} stopOpacity={0} />
                  </linearGradient>
                </defs>
                <Area type="monotone" dataKey="change" strokeWidth={2} stroke="#16d59c" fill="url(#gradient)" />
                <Tooltip
                  contentStyle={{ display: 'none' }}
                  //@ts-ignore
                  formatter={(tooltipValue, name, payloads) => {
                    payload !== payloads.payload ? setPayload(fetchedData.indexOf(payloads.payload)) : console.log('')
                  }}
                />
                <YAxis />
                <XAxis dataKey="name" />
              </AreaChart>
            </ResponsiveContainer>
          )}
        </ChartContainer>
      </ChartWrapper>
    </>
  )
}
