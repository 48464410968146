import { POOLS_API, POOLS_LENGHT_API } from './endpoints'
import { instanceProxy } from './stats'

export enum NetworkType {
  TESTNET = 'testnet',
  MAINNET = 'mainnet'
}

export enum ChainType {
  LBX = 2702,
  BSC = 56
}

export const getMyPools = async (
  network: ChainType | undefined,
  direction = 'desc',
  orderBy = 'liquidityProviderCount',
  currentPage = 1,
  perPage = 10
) => {
  let currentNetwork: NetworkType = NetworkType.TESTNET
  console.log(currentNetwork)
  switch (network) {
    case ChainType.LBX:
      currentNetwork = NetworkType.TESTNET
      break
    default:
      currentNetwork = NetworkType.MAINNET
      break
  }

  const { data } = await instanceProxy.get(
    `${POOLS_API}?network=testnet&from=${(currentPage - 1) * perPage}&to=${currentPage *
      perPage}&orderBy=${orderBy}&direction=${direction}`
  )
  return data
}

export const getPoolsLenght = async (network: ChainType | undefined) => {
  let currentNetwork: NetworkType = NetworkType.TESTNET
  switch (network) {
    case ChainType.LBX:
      currentNetwork = NetworkType.TESTNET
      break
    default:
      currentNetwork = NetworkType.MAINNET
      break
  }

  const { data } = await instanceProxy.get(`${POOLS_LENGHT_API}?network=${currentNetwork}`)
  return data
}
