import styled from 'styled-components'

export const MainCont = styled.div`
  user-select: none;
  -webkit-user-drag: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100vw - (100vw - 100%));
  min-height: 100vh;
  overflow: hidden;
  background-color: ${({ theme }) => theme.bg2};
  position: relative;
`

export const WrapperContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
`
export const HeaderContainer = styled.div`
  margin-top: 50px;
  width: 100%;
  background-color: ${({ theme }) => theme.bg1};
  border-radius: 32px;
  display: flex;
  box-shadow: 0px 16px 30px rgba(42, 81, 225, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
`

export const HeaderContainerWrapper = styled.div`
  width: 90%;
  height: 90%;
  display: flex;
  padding: 20px;
  ${({ theme }) => theme.mediaWidth.laptop`
    flex-direction: column;
    gap: 50px;
  `};
`

export const HeaderLeft = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  ${({ theme }) => theme.mediaWidth.laptop`
    width: 100%;
  `};
`

export const Title = styled.span`
  font-size: 60px;
  font-weight: 700;
  line-height: 65px;
  color: ${({ theme }) => theme.text1};
`

export const UnderTitle = styled.span`
  font-size: 26px;
  font-weight: 400;
  color: ${({ theme }) => theme.text2};
  line-height: 36px;
`
export const HeaderRight = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  ${({ theme }) => theme.mediaWidth.laptop`
    width: 100%;
  `};
`

export const BeeHoneypot = styled.img`
  aspect-ratio: 0.92;
  width: 250px;
  height: 250px;
  margin: 0;
`

export const SearchContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: end;
  align-items: center;
  padding: 10px;
  gap: 10px;
  background-color: ${({ theme }) => theme.bg1};
  margin-top: 50px;
  border-radius: 5px;
  ${({ theme }) => theme.mediaWidth.laptop`
    width: 100%;
    flex-wrap: wrap;
  `};
`

export const SortByContainer = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.bg1};
  padding: 10px;
  min-width: 200px;
`

export const SortByTypeWrapper = styled.div`
  background-color: ${({ theme }) => theme.bg2};
  box-shadow: 0px 33px 40px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  position: relative;
  gap: 20px;
  min-width: 250px;
  :hover {
    cursor: pointer;
  }
`

export const SortByTypeDropdown = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0;
  transform: translateY(100%);
  z-index: 3;
  background-color: ${({ theme }) => theme.bg1};
  box-shadow: 0px 33px 40px rgba(0, 0, 0, 0.03);
  min-width: 200px;
`

export const SortByTypeMenuItem = styled.div`
  min-width: 250px;
  padding: 5px 10px;
  cursor: pointer;
  color: ${({ theme }) => theme.text1};
`

export const P2 = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.text1};
`

export const SearchWrapperWrapper = styled.div`
  width: 80%;
  min-height: 50px;
  background-color: ${({ theme }) => theme.bg2};
  box-shadow: 0px 33px 40px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  :hover {
    cursor: pointer;
  }
  ${({ theme }) => theme.mediaWidth.laptop`
    flex: 1;

  `};
`

export const SearchInput = styled.input`
  flex: 1;
  height: 100%;
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.text1};
  font-size: 16px;
  padding: 0 10px;
  outline: none;
  ::placeholder {
    color: ${({ theme }) => theme.text1};
  }
`

export const PaginationContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  background-color: ${({ theme }) => theme.bg1};
  border-radius: 5px;
  margin-top: 10px;
`
