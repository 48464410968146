import React from 'react'
import {
  ContentTextContainer,
  ContentTextDetails,
  H2,
  MainCont,
  Number,
  NumberBold,
  NumberedText,
  NumberedTextBold,
  P1,
  Text,
  TextBold
} from './style'
import ContentTextDetailsSmall from './ContentTextDetailsSmall'
import ScrollToTopButton from 'components/ScrollTopToButton'

export const AgreementPage = () => {
  return (
    <MainCont>
      <ScrollToTopButton />
      <ContentTextContainer>
        <H2>WEBSITE TERMS AND CONDITIONS AND PRIVACY POLICY</H2>
        <H2>LBX CHAIN (PTY) LTD</H2>
        <H2>TRADING AS “LIBEX”</H2>
        <P1>(Registration No: 2022/671116/07) </P1>
        <P1 style={{ display: 'inline' }}>
          of <H2 style={{ display: 'inline' }}>345 RIVONIA ROAD, RIVONIA, GAUTENG, SOUTH AFRICA, 2128 </H2>
        </P1>
        <P1 style={{ display: 'inline' }}>
          (as the <H2 style={{ display: 'inline' }}>“Company”</H2>)
        </P1>
      </ContentTextContainer>
      <ContentTextDetails>
        <NumberedText>
          <Number>1</Number>
          <Text>
            In terms of Section 11 of the Electronic Communications and Transactions Act 25 of 2002, as amended (“ECTA”)
            these Terms and Conditions (this “Policy”) are binding and enforceable against all persons that access the
            Libex Platform (the “Platform”) or any part thereof or make use of the Platform.
          </Text>
        </NumberedText>
      </ContentTextDetails>
      <ContentTextDetails>
        <NumberedText>
          <Number>2</Number>
          <Text>
            If you do not agree with the Policy, do not accept and do not click the accept box. Leave immediately and
            terminate use of the Platform. Any delay in leaving and terminating use of the Platform shall be regarded as
            the User’s full and unconditional understanding and acceptance of this Policy.
          </Text>
        </NumberedText>
      </ContentTextDetails>
      <ContentTextDetails>
        <NumberedTextBold>
          <NumberBold>3</NumberBold>
          <TextBold>DEFINITIONS AND INTERPRETATIONS</TextBold>
        </NumberedTextBold>
      </ContentTextDetails>
      <ContentTextDetails>
        <NumberedText>
          <Number></Number>
          <Text>In this Policy, unless the context indicates that some other meaning is intended:</Text>
        </NumberedText>
      </ContentTextDetails>
      <ContentTextDetails>
        <NumberedText>
          <Number>3.1</Number>
          <Text>
            <TextBold style={{ display: 'inline' }}>“User”</TextBold> means a Registered User who has completed the
            electronic registration for the Platform as a User who is a natural or a juristic person and utilises the
            Platform of the Company as listed on the hyperlink (the “hyperlink”);{' '}
            <Text style={{ display: 'inline', color: '#6d2036', fontWeight: 'bold', textDecoration: 'underline' }}>
              {' '}
              https://defi.libex.ai/{' '}
            </Text>
          </Text>
        </NumberedText>
      </ContentTextDetails>
      <ContentTextDetails>
        <NumberedText>
          <Number>3.2</Number>
          <Text>
            <TextBold style={{ display: 'inline' }}>“Libex Platform”</TextBold> or{' '}
            <TextBold style={{ display: 'inline' }}>“Platform”</TextBold> means the Online Platform located on the
            hyperlink as well as the exchange platform located at{' '}
            <Text style={{ display: 'inline', color: '#6d2036', fontWeight: 'bold', textDecoration: 'underline' }}>
              https://defi.libex.ai/
            </Text>
          </Text>
        </NumberedText>
      </ContentTextDetails>
      <ContentTextDetailsSmall
        number="3.3"
        text="Any words indicated with inverted commas and starting with a capital
            letter shall bear the definition of the phrase or concept
            immediately preceding same as if it were included as a definition in
            terms of this clause;"
      />
      <ContentTextDetailsSmall
        number="3.4"
        text="References herein to the singular include the plural and vice versa;
            and"
      />
      <ContentTextDetailsSmall
        number="3.5"
        text="Hyperlinks have been used in this Policy. The fact that some or all
            of the hyperlinks may be non-operational shall not play a role in
            the determination of the validity and interpretation of this Policy."
      />
      <ContentTextDetails>
        <NumberedTextBold>
          <NumberBold>4</NumberBold>
          <TextBold>GENERAL PERSONAL DATA COLLECTION </TextBold>
        </NumberedTextBold>
      </ContentTextDetails>
      <ContentTextDetailsSmall
        number="4.1"
        text="The Company collects and processes personal data in accordance with
            the provisions of the European Union General Data Protection
            Regulation (“GDPR”) and Protection of Personal Information Act, No 4
            of 2013 (“POPIA”), as amended and other regulations in force in the
            Republic of South Africa."
      />
      <ContentTextDetailsSmall
        number="4.2"
        text="The Company undertakes that all personal information will be held
            securely to the standard directed by POPIA and GDPR and that the
            Company has bound its service providers (“Operators”) to a similar
            standard as required and prescribed by POPIA and GDPR."
      />
      <ContentTextDetails>
        <NumberedTextBold>
          <NumberBold>5</NumberBold>
          <TextBold>USER INFORMATION</TextBold>
        </NumberedTextBold>
      </ContentTextDetails>
      <ContentTextDetailsSmall
        number="5.1"
        text="The Company will respect the privacy of the Registered Users and
            will take reasonable measures to protect it, as more fully detailed
            below."
      />
      <ContentTextDetailsSmall
        number="5.2"
        text="When registering on the Platform the Company will require that
            Registered Users provide it with personal information."
      />
      <ContentTextDetailsSmall
        number="5.3"
        text=" Should the Registered User’s personal information change, the
            Registered User shall inform the Company and provide it with updates
            to his/her/its personal information as soon as reasonably possible
            to enable the Company to update such personal information."
      />
      <ContentTextDetailsSmall
        number="5.4"
        text="The Registered User may choose to provide additional personal
            information to the Company, in which event the Registered User
            agrees to provide accurate and current information, and not to
            impersonate or misrepresent any person or entity or falsely state or
            otherwise misrepresent their affiliation with anyone or anything."
      />
      <ContentTextDetails>
        <NumberedTextBold>
          <NumberBold>6</NumberBold>
          <TextBold>PRIVACY STATEMENT IN TERMS OF THE GDPR AND POPIA </TextBold>
        </NumberedTextBold>
      </ContentTextDetails>
      <ContentTextDetailsSmall number="6.1" text="The following data will be collected:" />
      <ContentTextDetailsSmall
        number="6.1.1"
        text=" The Registered User’s data for compliance with the Financial
            Intelligence Centre Act 38 of 2001 as amended, and it’s regulations
            as published from time to time."
      />
      <ContentTextDetailsSmall number="6.1.2" text="The Platform shall also keep a ledger of all transactions." />
      <ContentTextDetailsSmall
        number="6.1.3"
        text=" The Registered User’s IP address, non-personal browsing habits and
            click patterns."
      />
      <ContentTextDetailsSmall
        number="6.1.4"
        text="As and when necessary, the Company may electronically collect,
            store, disclose and/or use the personal information."
      />
      <ContentTextDetailsSmall
        number="6.1.5"
        text="Information collected from Registered Users is required to utilise
            the functionality of the Platform and such information shall not be
            used for any other purpose without the Registered User’s prior
            consent."
      />
      <ContentTextDetailsSmall
        number="6.1.6"
        text="All information collected is kept strictly confidential, and all
                reasonable steps are taken to ensure that information is secured in
                storage until ultimate destruction. It will not be shared with any
                third party without the prior written consent of the Registered
                User."
      />
      <ContentTextDetailsSmall
        number="6.1.7"
        text="The Company will ensure that all it’s employees, third-party service
            providers, (including their employees and third-party service
            providers) having access to the Registered User’s personal
            information are bound by appropriate and legally binding
            confidentiality obligations in relation to the Registered User’s
            personal information and that such confidentiality meets any
            applicable law, regulation, legal process, or enforceable
            governmental request."
      />
      <ContentTextDetailsSmall
        number="6.1.8"
        text="The Registered User’s personal data will be hosted and stored in
            countries which might not have the adequacy decision of the European
            Union; other third-party contractors may have access to the
            Registered User’s data only for the purpose specified herein, and
            the access of such third parties is strictly controlled."
      />
      <ContentTextDetailsSmall
        number="6.1.9"
        text=" Whenever the Company is sending data to countries that are not
            providing the same level of protection as the EU’s GDPR, the Company
            will use appropriate safeguards to protect the Registered User’s
            personal data."
      />
      <ContentTextDetailsSmall
        number="6.2"
        text="The Company collects stores and uses the abovementioned information
            in order to:"
      />
      <ContentTextDetailsSmall number="6.2.1" text="Communicate requested information to Registered Users;" />
      <ContentTextDetailsSmall
        number="6.2.2"
        text="Respond to queries, responses or complaints submitted by Registered
            Users;"
      />
      <ContentTextDetailsSmall
        number="6.2.3"
        text="Process orders or applications for The Company Products and/or
            services;"
      />
      <ContentTextDetailsSmall
        number="6.2.4"
        text="Create Products or services that may meet the future requirements of Registered 
                  Users; "
      />
      <ContentTextDetailsSmall
        number="6.2.5"
        text="Provide Registered Users with access to restricted pages on the Platform; and"
      />
      <ContentTextDetailsSmall
        number="6.2.6"
        text="Compile non-personal statistical information about browsing habits, click patterns 
            and access to the Platform."
      />
      <ContentTextDetailsSmall
        number="6.3"
        text="Personal information detailed above is collected and/or stored either electronically using 
            “cookies”  or  is  provided  voluntarily  with  the Registered  User’s  knowledge and  consent.  
            The Registered User can determine any use of cookies through your browser settings but 
            note that turning off cookies may cause certain features of the online services or Platform 
            to be unavailable to the Registered User."
      />
      <ContentTextDetailsSmall
        number="6.4"
        text="The Company may further collect non-personal information, for example, the Registered 
            User’s IP address, the date and time of their visits to the Platform, and browser history, to 
            recognise the Registered User during any subsequent visits to the Platform and/or use of 
            the  online  services.  The  Company  may  further  use  this  non-personal  information  to develop future products and/or services to meet the Registered User’s requirements and 
            needs. "
      />
      <ContentTextDetailsSmall
        number="6.5"
        text="The  Company  owns  and  retains  all  rights  to  the  non-personal  statistical  information 
            collected and compiled by the Company."
      />
      <ContentTextDetailsSmall
        number="6.6"
        text="The Company will not share the Registered User’s personal information outside of the 
            Company except in the following cases: "
      />
      <ContentTextDetailsSmall number="6.6.1" text="With prior consent; " />
      <ContentTextDetailsSmall
        number="6.6.2"
        text="With  its  employees  and/or  third-party  service  providers  who  assist  with  the  
                  Platform;"
      />
      <ContentTextDetailsSmall
        number="6.6.3"
        text="When processing of information is necessary for the performance of a contract with 
                  the Registered User; "
      />
      <ContentTextDetailsSmall
        number="6.6.4"
        text="When The Company has a legal obligation to share the information; "
      />
      <ContentTextDetailsSmall
        number="6.6.5"
        text="When  the  information  is  necessary  in  order  to  protect  the  vital  interests  of  the  
            Registered User or of another person;"
      />
      <ContentTextDetailsSmall
        number="6.6.6"
        text="When the information is necessary for the performance of a task carried out in the 
            public interest or the exercise of official authority vested;"
      />{' '}
      <ContentTextDetailsSmall
        number="6.6.7"
        text="When  the  information  is  necessary  for  the  purposes  of  the  legitimate  interests  
            pursued  by  the  Company  or  by  a  third  party,  except  where  such  interests  are  
            overridden by the interests or fundamental rights and freedoms of the Registered 
            User which require protection of personal data. "
      />
      <ContentTextDetailsSmall number="6.7" text="A User is not allowed (hereinafter “Prohibited Practices”) to:" />
      <ContentTextDetailsSmall
        number="6.7.1"
        text="Use the Platform in a manner which may cause damage to the Company, other 
            Users or any third party;"
      />
      <ContentTextDetailsSmall
        number="6.7.2"
        text="Undermine the security or integrity of any of the Company’s computing systems 
            or networks;"
      />
      <ContentTextDetailsSmall
        number="6.7.3"
        text="Use the Platform in any way to impair functionality or interfere with other Users;"
      />
      <ContentTextDetailsSmall number="6.7.4" text="Access the Platform or Website without permission;" />
      <ContentTextDetailsSmall number="6.7.5" text="Make use of the Company’s systems to commit fraud;" />
      <ContentTextDetailsSmall
        number="6.7.6"
        text="Act in a manner that is disrespectful or abusive to the Company systems and 
            staff;"
      />
      <ContentTextDetailsSmall number="6.7.7" text="publish, upload, exchange or transmit Prohibited Content; " />
      <ContentTextDetailsSmall
        number="6.7.8"
        text="publish,  upload,  exchange  or  transmit  any  content  that  the  Registered  User  
            knows to be false or untrue, or has justifiable reasons to believe it to be false or 
            untrue,  and  whose use  may  cause damage to  the  Company  , other  Users  or  
            third parties; "
      />
      <ContentTextDetailsSmall
        number="6.7.9"
        text="for  any  Registered  User  misrepresenting  him/her/itself  for  the  purpose  of  
            deceiving the Company, the Users or third parties;"
      />
      <ContentTextDetailsSmall
        number="6.7.10"
        text="publish,  upload,  exchange  or  transmit  to  the  Company,  other  Users  or  third  
            parties  any  unwanted  notices  or  other  content  of  commercial  or  malicious 
            nature,  without  prior  request  or  consent,  mainly  including  notices  and  other  
            content of the same or similar nature; "
      />
      <ContentTextDetailsSmall
        number="6.7.11"
        text="purposeful  publishing,  uploading,  exchange  or  transmission  of  any  content  
            containing computer viruses, worms, and programs that may obstruct or hinder 
            the  regular  operation  of  the  Platform,  cause  damage  or  destruction  of  any  
            computer  program,  or  any  computer  and  other  equipment  owned  by  the  
            Company other Users or third parties; "
      />
      <ContentTextDetailsSmall
        number="6.7.12"
        text="collect, process or use personal data of the Registered Users or third parties in 
            an unauthorised manner;"
      />
      <ContentTextDetailsSmall
        number="6.7.13"
        text="engage  in  overt  or  covert  advertising  (verbal  or  graphic  representation  of  
              Products, personal names, names of companies, names, trademarks 
              (registered or unregistered trademarks and service marks), businesses etc.) in 
              an unauthorised manner; "
      />
      <ContentTextDetailsSmall
        number="6.7.14"
        text="take  any  acts of  unfair  competition,  will  not  disturb  the  normal  order  of  online  
            transactions, and will not engage in any acts unrelated to online transactions;"
      />
      <ContentTextDetailsSmall
        number="6.7.15"
        text="not use any data on the Company for commercial purposes, including but not 
            limited to using any data displayed on the Platform through copy, dissemination 
            or any other means without prior written consent of the Company; "
      />
      <ContentTextDetailsSmall
        number="6.8"
        text="The Company shall have the right to unilaterally determine whether a User has violated 
            any  of  the  covenants  above  and,  according  to  such  unilateral  determination,  apply  
            relevant  rules  and  take  actions  thereunder  or  terminate  services  to  a  User,  without  its  
            consent or prior notice to it. As required to maintain the order and security of transactions 
            on the Platform, the Company shall have the right to close relevant orders and take other 
            actions  in  case  of  any  malicious  sale  or  purchase  or  any  other  events  disturbing  the  
            normal order of transaction of the market."
      />
      <ContentTextDetailsSmall
        number="6.8.1"
        text="If the violation or infringement has been held by any effective legal documents 
            issued by judicial or administrative authorities, or the Company determines at 
            its  sole  discretion  that  it  is  likely  that  the  User  has  violated  the  terms  of  this  
            agreement  or  any  other  applicable  rule,  law  or  regulation,  the  Company  shall  
            have the right to publish on the Platform such alleged violations and the actions 
            that having been taken against the User by the Company. "
      />
      <ContentTextDetailsSmall
        number="6.8.2"
        text="The Company shall have the right to delete any information published by User 
            on  the  Platform  in  violation  of  this  Policy  any  notice  to  the  User  and  impose  
            penalties  for  the  publication  of  such  information  which  shall  be  at  the  sole discretion of the Company and in accordance with the relevant rules, legislation 
            and regulations that may apply. "
      />
      <ContentTextDetailsSmall
        number="6.8.3"
        text="As to any act a User may have carried out on the Platform, including those not 
            carried out on the Platform but have had impacts on the Platform, the Company 
            and  its  users,  the  Company  shall  have  the  right  to  unilaterally  determine  its  
            nature and whether it constitutes violation of this Policy or any rules, and impose 
            punishments accordingly."
      />
      <ContentTextDetailsSmall
        number="6.8.4"
        text="The  Company  shall  not  be  liable  for  any  loss,  damage,  cost;  reputational  or  
            otherwise, incurred by a User as result penalties imposed by it due to a User’s 
            violation of this Policy. "
      />
      <ContentTextDetails>
        <NumberedTextBold>
          <NumberBold>7</NumberBold>
          <TextBold>COLLECTION AND PROCESSING OF PERSONAL DATA BY OTHER USERS OR THIRD PARTIES </TextBold>
        </NumberedTextBold>
      </ContentTextDetails>
      <ContentTextDetailsSmall
        number="7.1"
        text="The  Platform  contains  content  and  may  contain  Links  to  third-party  Platform,  through  
            which other Registered Users or third parties may gain authorised or unauthorised access 
            to  the  Registered  User’s  personal  data.  This  Policy  do  not  apply  to  the  collection,  
            processing or use of personal data that the Registered User has communicated to other 
            Registered  Users  and/or  third  parties.  It  is  in  the  Registered  User’s  best  interest  to  
            acquaint  themselves  with  the  rules  of  personal  data  protection,  and  the  protection  of  
            privacy applied by other Registered Users and/or third parties."
      />
      <ContentTextDetails>
        <NumberedTextBold>
          <NumberBold>8</NumberBold>
          <TextBold>MODIFICATION AND DELETION OF PERSONAL DATA</TextBold>
        </NumberedTextBold>
      </ContentTextDetails>
      <ContentTextDetailsSmall
        number="8.1"
        text="The Registered User is legally entitled to request modification or deletion of their personal 
            data or deletion from the Registered User database at any time. Modification or deletion 
            of data shall be effected on the basis of an appropriate notice addressed to The Company. "
      />
      <ContentTextDetails>
        <NumberedTextBold>
          <NumberBold>9</NumberBold>
          <TextBold>USER ACCOUNT SECURITY </TextBold>
        </NumberedTextBold>
      </ContentTextDetails>
      <ContentTextDetailsSmall
        number="9.1"
        text="A User shall be solely responsible for the safekeeping its account and password. A User 
            shall  be  responsible  for  all  activities  under  its  log-in  email,  account  and  password  
            (including  but  not  limited  to  information  disclosure,  information  posting,  consent  to  or  
            submission of various rules and agreements by clicking on the website, online renewal of 
            agreement or online purchase of services, etc.)."
      />
      <ContentTextDetailsSmall number="9.2" text="A User hereby agrees that:" />
      <ContentTextDetailsSmall
        number="9.2.1"
        text="A User will strictly observe the security, authentication, dealing, charging, 
                  withdrawal mechanism or procedures of the Website/ Platform/ service; and"
      />
      <ContentTextDetailsSmall
        number="9.2.2"
        text="A User will log out of the Platform by taking proper steps at the end of every visit. 
            The  Company  will  not  be  responsible  for any  loss  caused  by  a  User’s  failure  to  
            comply with this provision. "
      />
      <ContentTextDetails>
        <NumberedTextBold>
          <NumberBold>10</NumberBold>
          <TextBold>DISCLOSURES REQUIRED BY SECTION 43 OF THE ECTA </TextBold>
        </NumberedTextBold>
      </ContentTextDetails>
      <ContentTextDetailsSmall
        number=""
        text="Access  to  the  services,  content,  software  and  downloads  available  from  the  Online  Products  
            may be classified as “electronic transactions” as defined in terms of ECTA and you may have 
            the rights detailed in ECTA. Accordingly, the following information is provided:"
      />
      <ContentTextDetailsSmall
        number="10.1"
        text="The full name and legal status of the Online Product owner: The Company"
      />
      <ContentTextDetailsSmall
        number="10.2"
        text="Physical Address: 345 Rivonia Road, Rivonia, Gauteng, South Africa, 2128 "
      />
      <ContentTextDetailsSmall number="10.3" text="Main business: Blockchain Solutions" />
      <ContentTextDetails>
        <NumberedText>
          <Number>10.4</Number>
          <Text>
            The Website address of the Website is:{' '}
            <Text style={{ display: 'inline', textDecoration: 'underline' }}> https://defi.libex.ai/</Text>
          </Text>
        </NumberedText>
      </ContentTextDetails>
      <ContentTextDetails>
        <NumberedText>
          <Number>10.5</Number>
          <Text>
            The official e-mail address of the Website is:
            <Text style={{ display: 'inline', textDecoration: 'underline' }}>support@libex.ai/</Text>
          </Text>
        </NumberedText>
      </ContentTextDetails>
      <ContentTextDetailsSmall
        number="10.6"
        text="Access to and use of the Website is provided free of charge and paid for; "
      />
      <ContentTextDetails>
        <NumberedText>
          <Number>10.7</Number>
          <Text>
            Users may lodge complaints concerning the Platform:
            <Text style={{ display: 'inline', textDecoration: 'underline' }}>support@libex.ai/</Text>
          </Text>
        </NumberedText>
      </ContentTextDetails>
      <ContentTextDetailsSmall
        number="10.8"
        text="Any  disputes  between  Users  arising  from  any  transaction  on  the  Platform,  shall  be  
            submitted to the company via the dispute reporting mechanism available on the Platform. 
            Once submitted, the Company shall have the sole discretion to make a determination on 
            such dispute. A User hereby acknowledges and accepts the discretion and decision of the 
            Company. A User further, acknowledges and agree that, the Company may, on requests 
            from governmental authorities (including judicial and administrative departments), provide 
            User information, transaction records and any other necessary information. If it is alleged 
            that  a  User  has  infringed  upon  another  User’s  intellectual  rights  or  other  legitimate  
            interests,  the  Company  may  provide  the  necessary  ID  information  of  such  User  to  the  
            interest holder if the Company preliminarily decides that the infringement exists. "
      />
      <ContentTextDetails>
        <NumberedTextBold>
          <NumberBold>11</NumberBold>
          <TextBold>TERMINATION</TextBold>
        </NumberedTextBold>
      </ContentTextDetails>
      <ContentTextDetailsSmall
        number="11.1"
        text="The  Company  shall  have  the  right  to  terminate  all  or  part  of  the  Service  to  the  User, 
            temporarily freeze or permanently freeze (cancel) the authorizations of your account on 
            the Platform at the Company’s sole discretion, without any prior notice, due any Prohibited 
            Practice committed by the User or if the outcome of a dispute in terms of 11.3 directs that 
            the Company will be doing so. "
      />
      <ContentTextDetailsSmall
        number="11.2"
        text="The Company shall have the right to keep and use the transaction data, records and other 
            information that is related to a User’s account subsequent to termination. "
      />
      <ContentTextDetailsSmall
        number="11.3"
        text="In  case  of  any  of  the  following  events,  the  Company  shall  have  the  right  to  directly  
            terminate  this  agreement  by  cancelling  a  User’s  account,  and  shall  have  the  right  to  
            permanently  freeze  (cancel)  the  authorizations  of  your  account  on  the  Platform  and  
            withdraw the corresponding Company account thereof: "
      />
      <ContentTextDetailsSmall
        number="11.3.1"
        text=" after the Company terminates services to the User, should the Company detect that 
            the  User  may  have  registered  or  should  the  User  register  in  any  other  person’s  
            name as a Platform user again, directly or indirectly;"
      />
      <ContentTextDetailsSmall
        number="11.3.2"
        text=" should  the  main  content  of  the  User  information  that  the  User  provided  be  
            untruthful, inaccurate, outdated or incomplete; "
      />
      <ContentTextDetailsSmall
        number="11.3.3"
        text="should the User expressly state and notify Company of your unwillingness to accept 
            any amendment of this Policy; "
      />
      <ContentTextDetailsSmall
        number="11.3.4"
        text="any  other  circumstances  where  the  Company  deems  it  should  terminate  the  
            services."
      />
      <ContentTextDetailsSmall
        number="11.4"
        text="After  the  account  service  is  terminated  or  the  authorizations  of  a  User  account  on  the  
            Platform is permanently frozen (cancelled), the Company shall not have any duty to keep 
            or disclose to you any information in your account or forward any information you have 
            not read or sent to you or any third party."
      />
      <ContentTextDetailsSmall
        number="11.5"
        text="After the Company suspends or terminates Services to the User, the User’s transaction 
            activities  prior  to  such  suspension  or  termination  will  be  dealt  with  according  to  the  
            following principles: "
      />
      <ContentTextDetailsSmall
        number="11.5.1"
        text="The  Company  shall  have  the  right  to  delete,  at  the  same  time  of  suspension  or  
            termination of services, information related to any un-traded coin tokens that the 
            User uploaded to the Platform prior to the suspension or termination; "
      />
      <ContentTextDetailsSmall
        number="11.5.2"
        text="If  you  have  reached  any  purchase  agreement  with  any  other  User  prior  to  the  
            suspension or termination but such agreement has not been actually performed, 
            the Company shall have the right to delete information related to such purchase 
            agreement and the coins in question;"
      />
      <ContentTextDetailsSmall
        number="11.5.3"
        text="If  you  have  reached  any  purchase  agreement  with  any  other  User  prior  to  the  
            suspension or termination and such agreement has been partially performed, the 
            Company may elect not to delete the transaction; provided, however, the Company 
            shall have the right to notify your counterparty of the situation at the same time of 
            the suspension or termination."
      />
      <ContentTextDetails>
        <NumberedTextBold>
          <NumberBold>12</NumberBold>
          <TextBold>WARRANTY AND DISCLAIMER </TextBold>
        </NumberedTextBold>
      </ContentTextDetails>
      <ContentTextDetailsSmall
        number="12.1"
        text="In as much as the Company may collect information described in this Privacy Policy that 
            may be financial in nature, this Agreement shall not create any obligation on the part of the Company to comply with any reporting obligation that the User may have to the South 
            African Revenue Service or any other financial authority."
      />
      <ContentTextDetailsSmall
        number="12.2"
        text="The Company is not a financial services provider and does not offer financial or advisory 
            advice, any activity on the Platform is therefore at the risk of the User."
      />
      <ContentTextDetailsSmall
        number="12.3"
        text="TThe User acknowledges and warrants any obligation described in 12. shall be borne by 
            the User."
      />
      <ContentTextDetails>
        <NumberedTextBold>
          <NumberBold>13</NumberBold>
          <TextBold>LINKS TO OTHER SITES </TextBold>
        </NumberedTextBold>
      </ContentTextDetails>
      <ContentTextDetailsSmall
        number="13.1"
        text="The Website and/or Platform may contain links to other sites. If a User clicks on a third- 
            party link, it will be directed to that site. Note that these external sites are not operated by 
            the Company."
      />
    </MainCont>
  )
}
