/* eslint-disable @typescript-eslint/ban-ts-ignore */
import { ChainId } from '@uniswap/sdk'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Text } from 'rebass'
import { NavLink } from 'react-router-dom'
import { darken } from 'polished'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import Logo from '../../assets/svg/logo.svg'
import LogoDark from '../../assets/svg/logo_white.svg'
import { useActiveWeb3React } from '../../hooks'
import { useDarkModeManager } from '../../state/user/hooks'
import { useETHBalances } from '../../state/wallet/hooks'
// import { ExternalLink } from '../../theme'

import { AlertTriangle, Moon, Sun } from 'react-feather'

import Row, { RowFixed } from '../Row'
import Web3Status from '../Web3Status'
import { supportedChainIds } from 'connectors'
import { NETWORK_CONFIGS } from '../../constants'
import { useWeb3React } from '@web3-react/core'
import LanguageSelector from '../LanguageSelector'

const HeaderFrame = styled.div`
  display: grid;
  grid-template-columns: 1fr 120px;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  top: 0;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1rem;
  z-index: 2;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 1fr;
    padding: 0 1rem;
    width: calc(100%);
    position: relative;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        padding: 0.5rem 1rem;
  `}
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row;
    justify-content: space-between;
    justify-self: center;
    width: 100%;
    max-width: 960px;
    padding: 1rem;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 99;
    height: 72px;
    border-radius: 12px 12px 0 0;
    background-color: ${({ theme }) => theme.bg1};
  `};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;

  /* addresses safari's lack of support for "gap" */
  & > *:not(:first-child) {
    margin-left: 8px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
   flex-direction: row-reverse;
    align-items: center;
  `};
`

const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;
`

const HeaderRow = styled(RowFixed)`
  ${({ theme }) => theme.mediaWidth.upToMedium`
   width: 100%;
  `};
`

const HeaderLinks = styled(Row)`
  justify-content: center;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem 0 1rem 1rem;
    justify-content: flex-end;
`};
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg3)};
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;

  :focus {
    border: 1px solid blue;
  }
`

const HideSmall = styled.span``
const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const Title = styled.a`
  all: unset;
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: center;
  `};
  :hover {
    cursor: pointer;
  }
`

const UniIcon = styled.div`
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-5deg);
  }
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 1rem;
  width: fit-content;
  margin: 0 12px;
  font-weight: 500;
  position: relative;

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.text1};
    &:after {
      content: '';
      position: absolute;
      display: block;
      margin: 0 auto;
      transform: skewX(-45deg);
      bottom: -8px;
      height: 3px;
      width: 100%;
      background-color: #f8a137;
    }
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }
  ${({ theme }) => theme.mediaWidth.mobileS`
    margin: 0 5px;
  `}
`

// const StyledExternalLink = styled(ExternalLink).attrs({
//   activeClassName
// })<{ isActive?: boolean }>`
//   ${({ theme }) => theme.flexRowNoWrap}
//   align-items: left;
//   border-radius: 3rem;
//   outline: none;
//   cursor: pointer;
//   text-decoration: none;
//   color: ${({ theme }) => theme.text2};
//   font-size: 1rem;
//   width: fit-content;
//   margin: 0 12px;
//   font-weight: 500;

//   &.${activeClassName} {
//     border-radius: 12px;
//     font-weight: 600;
//     color: ${({ theme }) => theme.text1};
//   }

//   :hover,
//   :focus {
//     color: ${({ theme }) => darken(0.1, theme.text1)};
//   }

//   ${({ theme }) => theme.mediaWidth.upToExtraSmall`
//       display: none;
// `}
// `

export const StyledMenuButton = styled.button`
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 35px;
  background-color: ${({ theme }) => theme.bg3};
  margin-left: 8px;
  padding: 0.15rem 0.5rem;
  border-radius: 0.5rem;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    background-color: ${({ theme }) => theme.bg4};
  }

  svg {
    margin-top: 2px;
  }
  > * {
    stroke: ${({ theme }) => theme.text1};
  }
`
//network selector style
export const ListItem = styled.button`
  all: unset;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: calc(199px - 1rem);
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 0.8rem;
  border-radius: 0.5rem;
  color: ${props => props.theme.text7};
  padding: 0.5rem;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  &:first-child {
    margin-top: 1rem;
  }
  &:last-child {
    margin-bottom: 1rem;
  }
  &:hover {
    background-color: ${({ disabled }) => (disabled ? null : '#eaf8fd')};
    color: ${({ disabled }) => (disabled ? null : '#f8a137')};
  }
`
export const ListItemName = styled.p`
  all: unset;
  margin-left: 0.3rem;
`
export const NetworkImg = styled.img`
  height: 30px;
  width: 30px;
`

export const DropDownContainer = styled.div`
  max-width: 177px;
  min-width: 150px;
  position: relative;
  user-select: none;
  @media (max-width: 890px) {
    max-width: 100px;
    min-width: 90px;
  }
  ${({ theme }) => theme.mediaWidth.mobileL`
    max-width: 90px;
    min-width: 90px;
  `}
  ${({ theme }) => theme.mediaWidth.mobileS`
    max-width: 46px;
    min-width: 46px;
  `}
`

export const DropDownHeader = styled.div`
  max-width: 177px;
  min-width: 150px;
  height: 46px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50px;
  background-color: ${props => props.theme.bg2};
  position: relative;
  z-index: 100;
  &:hover {
    opacity: 0.9;
    box-shadow: 0px 0px 3px ${props => props.theme.text7};
  }
  @media (max-width: 890px) {
    max-width: 100px;
    min-width: 90px;
    justify-content: flex-start;
  }
  ${({ theme }) => theme.mediaWidth.mobileL`
    max-width: 74px;
    min-width: 74px;
  `}
  ${({ theme }) => theme.mediaWidth.mobileM`
    max-width: 46px;
    min-width: 46px;
  `}
  ${({ theme }) => theme.mediaWidth.mobileS`
    max-width: 46px;
    min-width: 46px;
  `}
`

export const NetworkIcon = styled.div`
  height: 46px;
  min-width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 0.2rem;
  background-color: ${props => props.theme.primary1};
`

export const ChainName = styled.span`
  font-weight: 400;
  font-size: 0.7rem;
  max-width: 50%;
  color: ${props => props.theme.text6};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `}
  @media (max-width: 890px) {
    display: none;
  }
`
export const ChevronContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.1rem;
  ${({ theme }) => theme.mediaWidth.mobileM`
    display: none;
  `}
`

export const DropDownListContainer = styled.div`
  position: absolute;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 110%;
  left: -20%;
  width: 200px;
  border-radius: 2rem;
  border: 1px solid ${props => props.theme.bg1};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    left: -60%;
    top: -400%;
  `}
  ${({ theme }) => theme.mediaWidth.mobileM`
    left: -200%;
  `};
  ${({ theme }) => theme.mediaWidth.mobileS`
    left: -350%;
  `};
`

export const DropDownList = styled.div`
  padding: 0;
  margin: 0;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid ${props => props.theme.bg1};
  border-bottom-right-radius: 2rem;
  border-bottom-left-radius: 2rem;
  color: white;
  background-color: ${props => props.theme.bg2};
  font-size: 1rem;
  font-weight: 500;
`
export const DropDownHead = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  align-items: center;
  background-color: ${props => props.theme.bg2};
  font-weight: 600;
  font-size: 16px;
  color: ${props => props.theme.text7};
  height: 4rem;
  border-bottom: 1px solid ${props => props.theme.bg1};
`

export const ChevronUp = styled.i`
  box-sizing: border-box;
  position: relative;
  display: block;
  color: ${props => props.theme.text7};
  transform: scale(var(--ggs, 1));
  transform: rotate(180deg);
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
  margin-right: 10px;
  &:after {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 10px;
    height: 10px;
    border-bottom: 2px solid;
    border-right: 2px solid;
    transform: rotate(45deg);
    left: 4px;
    top: 2px;
  }
  ${({ theme }) => theme.mediaWidth.mobileM`
    margin-right: 8px;
  `}
  @media (max-width: 890px) {
    transform: rotate(0deg);
  }
`
export const ChevronDown = styled.i`
  box-sizing: border-box;
  position: relative;
  display: block;
  color: ${props => props.theme.text7};
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
  margin-right: 10px;
  &:after {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 10px;
    height: 10px;
    border-bottom: 2px solid;
    border-right: 2px solid;
    transform: rotate(45deg);
    left: 4px;
    top: 2px;
  }
  @media (max-width: 890px) {
    transform: rotate(180deg);
  }
  ${({ theme }) => theme.mediaWidth.mobileL`
    margin-right: 8px;
  `}
`
export const StyledAlert = styled(AlertTriangle)`
  color: red;
  margin-right: 0.5rem;
  :hover {
    cursor: pointer;
  }
  @media screen and (max-width: 890px) {
    margin: 0 0 0 0.7rem;
  }
`
const LogoName = styled.span`
  font-size: 1.4rem;
  font-weight: 600;
  color: ${props => props.theme.text6};
  margin-left: 0.5rem;
  @media screen and (max-width: 890px) {
    display: none;
  }
`
// configurable:
const NETWORK_LABELS: { [chainId in ChainId]?: string } = {
  [ChainId.LIBEX_TESTNET]: 'Libex Testnet'
}

export async function changeNetwork(chainId: number) {
  if (window.ethereum)
    try {
      // @ts-ignore
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${chainId.toString(16)}` }]
      })
    } catch (switchError) {
      if (
        // @ts-ignore
        switchError.code === 4902 ||
        switchError?.data?.orginalError?.code === 4902 ||
        switchError?.code === -32002 ||
        switchError?.code === -32603
      ) {
        try {
          // @ts-ignore
          await ethereum.request({
            method: 'wallet_addEthereumChain',
            // @ts-ignore
            params: [NETWORK_CONFIGS[chainId]]
          })
        } catch (addError) {
          // handle "add" error
          console.error(addError)
        }
      }

      // handle other "switch" errors
      console.error(switchError)
    }
}

export default function Header() {
  const { account, error, chainId } = useWeb3React()
  const { active } = useActiveWeb3React()
  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']

  const { t } = useTranslation()
  const [darkMode, toggleDarkMode] = useDarkModeManager()

  const [isOpen, setIsOpen] = useState(false)
  const Dialogue = useRef<any>(null)

  //UseEffect for clicks outside of the dropdown
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (Dialogue.current && !Dialogue?.current?.contains(event.target)) {
        setIsOpen(false)
      }
    }
    isOpen && document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen])

  const NetworkList = useMemo(
    () =>
      supportedChainIds.map((id, index) => (
        <ListItem
          disabled={chainId ? (chainId === id ? true : false) : false}
          onClick={() => {
            changeNetwork(id)
            setIsOpen(false)
          }}
          key={index}
        >
          <NetworkImg alt={`${id}`} src={require(`../../assets/images/network-images/${id}.png`)} />
          <ListItemName>{NETWORK_LABELS[id]}</ListItemName>
        </ListItem>
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chainId, error]
  )

  return (
    <HeaderFrame>
      <HeaderRow>
        <Title href="/#/home">
          <UniIcon>
            <img width={'30px'} src={darkMode ? LogoDark : Logo} alt="logo" />
          </UniIcon>
          {/* configurable: */}
          <LogoName>Libex</LogoName>
        </Title>
        <HeaderLinks>
          <StyledNavLink id={`swap-nav-link`} to={'/swap'}>
            {t('swap')}
          </StyledNavLink>
          <StyledNavLink
            id={`pool-nav-link`}
            to={'/pool'}
            isActive={(match, { pathname }) =>
              Boolean(match) ||
              pathname.startsWith('/add') ||
              pathname.startsWith('/remove') ||
              pathname.startsWith('/create') ||
              pathname.startsWith('/find')
            }
          >
            {t('Liquidity')}
          </StyledNavLink>
          <StyledNavLink id={`pool-nav-link`} to={'/pool-list'}>
            {t('Pools')}
          </StyledNavLink>
          <StyledNavLink id={`learn-nav-link`} to={'/learn'}>
            {t('learn')}
          </StyledNavLink>
          {/* 
            <StyledExternalLink id={`stake-nav-link`} href={'https://uniswap.info'}>
                Charts <span style={{ fontSize: '11px' }}>↗</span>
              </StyledExternalLink>
            */}
        </HeaderLinks>
      </HeaderRow>
      <HeaderControls>
        <HeaderElement>
          <LanguageSelector />
          <HideSmall>
            {active && (
              <DropDownContainer ref={Dialogue}>
                <DropDownHeader onClick={() => setIsOpen(!isOpen)}>
                  {supportedChainIds.some(element => element === chainId) ? (
                    <NetworkIcon>
                      <NetworkImg
                        // configurable:
                        alt={`${chainId}`}
                        src={require(`../../assets/images/network-images/${chainId}.png`)}
                      />
                    </NetworkIcon>
                  ) : (
                    <StyledAlert />
                  )}{' '}
                  <ChainName>
                    {account
                      ? supportedChainIds.some(element => element === chainId)
                        ? NETWORK_LABELS[chainId as ChainId]
                        : null
                      : t('unsupportedNetwork')}
                  </ChainName>
                  <ChevronContainer>{isOpen ? <ChevronUp /> : <ChevronDown />}</ChevronContainer>
                </DropDownHeader>
                {isOpen && (
                  <DropDownListContainer>
                    <DropDownHead>{t('selectNetwork')}</DropDownHead>
                    <DropDownList>{NetworkList}</DropDownList>
                  </DropDownListContainer>
                )}
              </DropDownContainer>
            )}
          </HideSmall>
          <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
            {account && userEthBalance ? (
              <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                {/* configurable: */}
                {userEthBalance?.toSignificant(4)} LBX
              </BalanceText>
            ) : null}
            <Web3Status />
          </AccountElement>
        </HeaderElement>
        <HeaderElementWrap>
          <StyledMenuButton onClick={() => toggleDarkMode()}>
            {darkMode ? <Moon size={20} /> : <Sun size={20} />}
          </StyledMenuButton>
        </HeaderElementWrap>
      </HeaderControls>
    </HeaderFrame>
  )
}
