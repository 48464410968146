import styled from 'styled-components'

export const ChartWrapper = styled.div<{ isOpenChart: boolean }>`
  width: 100%;
  height: 100%;
  display: ${({ isOpenChart }) => (isOpenChart ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  border-radius: 32px;
  box-shadow: 0px 16px 30px rgba(42, 81, 225, 0.1);
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `}
`

export const ChartContainer = styled.div`
  background-color: ${props => props.theme.bg1};
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
`
