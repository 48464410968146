import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useTitle from '../../hooks/useTitle'

const Container = styled.div`
  width: 100%;
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  user-select: none;
  padding-bottom: 10rem;
  user-zoom: none;
  background-color: ${props => props.theme.bg2};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding-bottom: 14rem;
  `}
`

const H1 = styled.h1`
  margin: 0;
  font-size: clamp(60px, 8vw, 90px);
  font-weight: 600;
  user-select: none;
  user-zoom: none;
  color: ${props => props.theme.text7};
`
const Text = styled.p`
  margin: 0;
  font-size: clamp(18px, 2vw, 25px);
  font-weight: 500;
  user-select: none;
  user-zoom: none;
  color: ${props => props.theme.text7};
`

const Image = styled.img`
  width: clamp(150px, 15vw, 180px);
  height: clamp(180px, 15vw, 200px);
`

const Button = styled.button`
  margin-top: 10px;
  border-radius: 10px;
  color: ${props => props.theme.text6};
  background-color: ${props => props.theme.bg1};
  border-color: ${props => props.theme.primary1};
  border-width: 2px;
  border-style: solid;
  width: clamp(180px, 20vw, 300px);
  height: clamp(2.2rem, 10vw, 3rem);
  align-items: center;
  display: inline-flex;
  font-family: inherit;
  font-size: 16.5px;
  font-weight: 600;
  justify-content: center;
  letter-spacing: 0.03em;
  line-height: 1;
  outline: 0;
  transition: background-color 0.2s, opacity 0.2s;
  cursor: pointer;

  &:hover {
    opacity: 0.65;
  }

  &:active {
    opacity: 0.85;
    transform: translateY(1px);
  }
`

const LinkDiv = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.text7};
`

export default function RedirectPage() {
  const { t }: any = useTranslation()
  useTitle('404')
  return (
    <Container>
      <Image alt={`Logo`} src={require('../../assets/images/lbs.png')} />
      <H1>404</H1>
      <Text>{t('pageNotFound')}</Text>
      <LinkDiv to={`/home`}>
        <Button>{t('returnHome')}</Button>
      </LinkDiv>
    </Container>
  )
}
