import useTitle from 'hooks/useTitle'
import React from 'react'
import { Text } from 'rebass'
import styled from 'styled-components'

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`
const LeftContainer = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.bg2};
  padding: 10px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
   width: 100%;
   padding: 20px;
  `};
`

const RightContainer = styled.div`
  display: flex;
  flex-direction: column !important;
  flex: 3;
  gap: 20px;
  background-color: ${({ theme }) => theme.flexRowNoWrap};
  padding-left: 20px;
  padding-right: 20px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
   width: 100%;
  `};
`

const SidebarItem = styled.div`
  margin-bottom: 10px;
  padding: 10px;
  background-color: ${({ theme }) => theme.bg1};
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`

const ContentContainer = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.bg1};
  padding: 10px;
`

export default function LearnPage() {
  useTitle('Learn')
  return (
    <MainContainer>
      <LeftContainer>
        <SidebarItem>Tutorial 1</SidebarItem>
        <SidebarItem>Tutorial 2</SidebarItem>
        <SidebarItem>Tutorial 3</SidebarItem>
      </LeftContainer>
      <RightContainer>
        <Text fontWeight={500} fontSize={26}>
          Libex doc
        </Text>
        <Text fontWeight={200} fontSize={16}>
          Libex doc
        </Text>
        <Text fontWeight={200} fontSize={16}>
          Libex is a decentralized exchange (DEX) that runs on Libex testnet (LBX), with lots of other features that let
          you earn and win tokens.
        </Text>
        <Text fontWeight={500} fontSize={26}>
          Features:
        </Text>
        <Text fontWeight={200} fontSize={16}>
          Documentation of Libex
        </Text>
        <Text fontWeight={200} fontSize={16}>
          Libex
        </Text>
        <ContentContainer>
          <Text fontWeight={200} fontSize={16}>
            Libex represents a sophisticated decentralized exchange (DEX) currently operating on the Libex Testnet.
            Imminently transitioning to the Libex Mainnet, it offers an extensive array of features designed to
            facilitate the earning and acquisition of tokens.
          </Text>
        </ContentContainer>
        <Text fontWeight={200} fontSize={16}>
          Libex represents a sophisticated decentralized exchange (DEX) currently operating on the Libex Testnet.
          Imminently transitioning to the Libex Mainnet, it offers an extensive array of features designed to facilitate
          the earning and acquisition of tokens.
        </Text>
        <ContentContainer>
          <Text fontWeight={200} fontSize={16}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio at voluptatum explicabo culpa vitae
            expedita accusamus magnam, magni nihil iusto. Cupiditate deleniti natus eligendi? Doloribus iure pariatur
            sunt officia eveniet! Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias aperiam vitae,
            repudiandae praesentium quas, aliquam saepe cum earum velit adipisci sed facere, repellendus corrupti nihil
            reiciendis. Fugit nisi voluptate laboriosam? Lorem ipsum dolor, sit amet consectetur adipisicing elit.
            Aspernatur atque nostrum ab ipsa nam consequatur numquam eum sed facere laudantium dicta dolore, porro
            optio, ducimus architecto mollitia eligendi perspiciatis vero!
          </Text>
        </ContentContainer>
      </RightContainer>
    </MainContainer>
  )
}
