import { useTranslation } from 'react-i18next'
import {
  BeeHoneypot,
  BottomLeftLogo,
  ButtonRowR,
  Card,
  CardH1,
  CardImage,
  CardImageBee,
  CardP1,
  CardRow,
  CardText,
  Column,
  DashRow,
  DotWithX,
  HeroButton,
  HeroSpan,
  HeroText,
  HomeContainer,
  HoneyDash,
  HoneyPotRow,
  IFRAME,
  LastDiv,
  LastText,
  LastTextH,
  LeftDiv,
  LeftHeroLogo,
  LeftLogo,
  LockedBeeHex,
  LogoContainer,
  MainRectangleDiv,
  MiddleText,
  RecButton,
  RectangleCardImg,
  RectangleContainer,
  RectangleDiv,
  RectangleDiv2,
  RectangleText,
  RectangleText2,
  RightButton,
  RightButton2,
  RightDiv,
  RightHeroLogo,
  RightText1,
  RightText2,
  Row,
  TextDiv,
  Wrap
} from './style'

import useTitle from '../../hooks/useTitle'
import React, { useEffect } from 'react'

//pictures
import rightHeroLogo from '../../assets/images/home/heroLogoRight.png'
import leftHeroLogo from '../../assets/images/home/heroLogoLeft.png'
import hivePic from '../../assets/images/home/hive.png'
import beeRightPic from '../../assets/images/home/beeRight.png'
import hexHoneyPic from '../../assets/images/home/hexHoney.png'
import lockedBeePic from '../../assets/images/home/beeHexLock.png'
import dotWithXPic from '../../assets/images/home/dotsWithX.png'
import beeHoneypotPic from '../../assets/images/home/beeHoneypot.png'
import beeGraphPic from '../../assets/images/home/beeGraph.png'
import bottomLeftLogoPic from '../../assets/images/home/bottomLeftLogo.png'
import leftLogoPic from '../../assets/images/home/leftLogo.png'
import { useGetMethod } from 'hooks/UseFetch'
import { getStats } from 'api/stats'
import { useWeb3React } from '@web3-react/core'

const Home = (): JSX.Element => {
  const { account, chainId } = useWeb3React()
  const { t } = useTranslation()

  const { data, refetch } = useGetMethod(
    'stats',
    getStats,
    account ? (chainId === 2702 ? 'testnet' : 'mainnet') : 'testnet',
    {
      chainId
    },
    false
  )
  useEffect(() => {
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, account])

  const formatNumber = (n: number) => {
    if (n < 1e3) return n
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + ' thousand'
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + ' million'
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + ' billions'
    if (n >= 1e12 && n < 1e15) return +(n / 1e12).toFixed(1) + ' trillion'
    if (n >= 1e15 && n < 1e18) return +(n / 1e15).toFixed(1) + ' quadrillion'
    if (n >= 1e18 && n < 1e21) return +(n / 1e18).toFixed(1) + ' quintillion'
    if (n >= 1e21 && n < 1e24) return +(n / 1e21).toFixed(1) + ' sextillion'
    if (n >= 1e24 && n < 1e27) return +(n / 1e24).toFixed(1) + ' septillion'
    if (n >= 1e27 && n < 1e30) return +(n / 1e27).toFixed(1) + ' octillion'
    if (n >= 1e30 && n < 1e33) return +(n / 1e30).toFixed(1) + ' nonillion'
    if (n >= 1e33 && n < 1e36) return +(n / 1e33).toFixed(1) + ' decillion'
    if (n >= 1e36) return +(n / 1e36).toFixed(1) + ' undecillion'
    return n
  }

  useTitle('Home')

  return (
    <HomeContainer>
      <LogoContainer>
        <RightHeroLogo src={rightHeroLogo} alt="logo" />
      </LogoContainer>
      <HeroText>
        {t('Trade, Swap')}
        <HeroSpan> {t('and')} </HeroSpan>
        {t('Earn')}
        <HeroSpan> {t('with')} </HeroSpan>
        Libex
      </HeroText>
      <Row justify="center" margin="80px">
        <HeroButton to="/swap" disabled={account} mode={1}>
          {account ? t('connected') : t('connectWallet')}
        </HeroButton>
        <HeroButton disabled={!account} to="/swap" mode={2}>
          {t('startTrading')}
        </HeroButton>
      </Row>
      <Wrap>
        <LeftHeroLogo src={leftHeroLogo} alt="logo" />
        <CardRow>
          <Card>
            <CardImage src={hivePic} alt="lockedBee" />
            <CardText size="28px" weight="600">
              {formatNumber(data?.numberOfUsers)} {` ${t('users')}`}
            </CardText>
            <CardText size="20px" weight="500" margin="36px 0px 0px 0px">
              {t('inTotal')}
            </CardText>
          </Card>
          <Card>
            <CardImageBee src={beeRightPic} alt="lockedBee" />
            <CardText size="28px" weight="600">
              {formatNumber(data?.numberOfTrades)} {` ${t('trades')}`}
            </CardText>
            <CardText size="20px" weight="500" margin="36px 0px 0px 0px">
              {t('madeInTotal')}
            </CardText>
          </Card>
          <Card>
            <CardImage src={hexHoneyPic} alt="lockedBee" />
            <CardText size="28px" weight="600">
              {formatNumber(data?.numberOfPairs)} {` ${t('pairsAvailable')}`}
            </CardText>
            <CardText size="20px" weight="500" margin="36px 0px 0px 0px">
              {t('onOurPlatform')}
            </CardText>
          </Card>
        </CardRow>
        <RectangleDiv>
          <RecButton to="/pool" mode={2}>
            {t('provideLiquidity')}
          </RecButton>
          <RectangleText2>{t('provideLiquidityText')}</RectangleText2>
          <Row justify="center">
            <HoneyDash />
          </Row>
          <RectangleText>{t('howAreWeDifferent')}</RectangleText>
          <LockedBeeHex src={lockedBeePic} alt="lockedBee" />
        </RectangleDiv>
        <LeftLogo src={leftLogoPic} alt="leftLogo" />
      </Wrap>
      <HoneyPotRow margin="3rem 0 0 0" justify="center">
        <BeeHoneypot src={beeHoneypotPic} alt="BeeHoneypot" />
        <RightDiv>
          <TextDiv>
            <RightText1>{t('tradeAnything')}</RightText1>
            <DashRow>
              <HoneyDash />
            </DashRow>
            <RightText2>{t('tradeAnythingText')}</RightText2>
            <ButtonRowR>
              <RightButton to="/swap">{t('tradeNow')}</RightButton>
              <RightButton2 to="/learn">{t('learn')}</RightButton2>
            </ButtonRowR>
          </TextDiv>
          <DotWithX src={dotWithXPic} alt="DotsWithX" />
        </RightDiv>
      </HoneyPotRow>

      <HoneyPotRow margin="3rem 0 0 0" justify="center" align="center">
        <LeftDiv>
          <RightText1>{t('earnPassiveIncome')}</RightText1>
          <DashRow>
            <HoneyDash />
          </DashRow>
          <RightText2>{t('earnPassiveIncomeText')}</RightText2>
          <ButtonRowR>
            <RightButton to="/pool">{t('explore')}</RightButton>
            <RightButton2 to="/learn">{t('learn')}</RightButton2>
          </ButtonRowR>
        </LeftDiv>
        <BeeHoneypot src={beeGraphPic} alt="BeeHoneypot" />
      </HoneyPotRow>
      <RectangleContainer>
        <RectangleDiv2 />
        <MainRectangleDiv>
          <Column>
            <CardH1>{t('buySwap')}</CardH1>
            <CardP1>{t('startJourney')}</CardP1>
            <RectangleCardImg src={require('../../assets/images/home/card.png')} />
          </Column>
          <IFRAME frameBorder={0} marginWidth={0} marginHeight={0} src="https://otc.libex.ai/quickBuySell"></IFRAME>
        </MainRectangleDiv>
      </RectangleContainer>
      <LastDiv>
        <LastTextH>{t('Start in seconds.')}</LastTextH>
        <Row justify="center" style={{ width: '86%' }}>
          <HoneyDash />
        </Row>
        <LastText>{t('connectInSeconds')}</LastText>
        <MiddleText>{t('learnHowToStart')}</MiddleText>
        <RecButton to="/swap" disabled={account} mode={1}>
          {account ? t('connected') : t('connectWallet')}
        </RecButton>
        <BottomLeftLogo src={bottomLeftLogoPic} alt="bottomLeftLogo" />
      </LastDiv>
    </HomeContainer>
  )
}

export default Home
