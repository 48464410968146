import React from 'react'
import styled from 'styled-components'
import Settings from '../Settings'
import { RowBetween } from '../Row'
import { TYPE } from '../../theme'
import { useTranslation } from 'react-i18next'

const StyledSwapHeader = styled.div`
  padding: 20px 1rem 0px 1.5rem;
  margin-bottom: -4px;
  width: 100%;
  max-width: 420px;
  color: ${({ theme }) => theme.text2};
  border-bottom: 2px solid ${props => (props.theme.currentTheme === 'dark' ? '#d2d2d225' : '#3f3f3f26')};
`

const IconContainer = styled.div`
  margin-top: 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 7px;
`

export const SlippageCont = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  padding: 0 0.5rem 0 0.5rem;
  width: 100%;
  margin-top: 10px;
`

export default function SwapHeader() {
  const { t } = useTranslation()
  return (
    <StyledSwapHeader>
      <RowBetween>
        <TYPE.black fontWeight={800} fontSize={18}>
          {t('swap')}
        </TYPE.black>
      </RowBetween>
      <RowBetween style={{ marginTop: '7px' }}>
        <TYPE.black fontWeight={400}>{t('tradeTokensInAnInstant')}</TYPE.black>
      </RowBetween>
      <RowBetween>
        <IconContainer>
          <Settings />
        </IconContainer>
      </RowBetween>
    </StyledSwapHeader>
  )
}
