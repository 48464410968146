import { useQuery } from '@tanstack/react-query'

export const useGetMethod = <T>(
  key: any,
  method: any,
  arg?: any,
  options?: any,
  isConnected?: boolean,
  chainId?: number
) => {
  return useQuery(
    [key],
    async () => {
      const data = await method(arg)
      return data
    },
    {
      enabled: isConnected,
      ...options,
      cacheTime: 0,
      variables: chainId
    }
  )
}
