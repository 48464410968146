import { FacebookIcon, InstagramIcon, TwitterIcon } from 'theme'

export const socialMediaLinks = [
  {
    path: 'https://twitter.com/LibexMarktplace',
    icon: TwitterIcon
  },
  {
    path: 'https://facebook.com/LibexMarketplace/',
    icon: FacebookIcon
  },
  {
    path: 'https://instagram.com/libex.marketplace/',
    icon: InstagramIcon
  }
]

export const footerLinks = [
  {
    name: 'Libex NFT Marketplace',
    path: 'https://nft.libex.ai/'
  },
  {
    name: 'Libex OTC',
    path: 'https://otc.libex.ai/'
  },
  {
    name: 'Libex Quota',
    path: 'https://quota.libex.ai/'
  }
]
