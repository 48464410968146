import styled from 'styled-components'

export const MainCont = styled.div`
  -webkit-user-drag: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100vw - (100vw - 100%));
  min-height: 100vh;
  overflow: hidden;
  background-color: ${({ theme }) => theme.bg2};
  position: relative;
`

export const ContentHeader = styled.div`
  margin-top: 50px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ContentTextContainer = styled.div`
  margin-top: 50px;
  width: 90%;
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
`

export const ContentTextDetails = styled.div`
  margin-top: 50px;
  width: 90%;
`

export const H1 = styled.h1`
  font-size: 2.5rem;
  font-weight: 500;
  color: ${({ theme }) => theme.text1};
  margin: 0;
  padding: 0;
  text-align: center;
`

export const H2 = styled.h1`
  font-size: 2.5rem;
  font-weight: 300;
  color: ${({ theme }) => theme.text1};
  margin: 0;
  padding: 0;
  text-align: center;
`

export const P1 = styled.p`
  font-size: 1.5rem;
  font-weight: 300;
  color: ${({ theme }) => theme.text1};
  margin: 0;
  padding: 0;
  text-align: center;
`

export const NumberedText = styled.div`
  display: flex;
  align-items: start;
  gap: 30px;
`

export const Number = styled.span`
  font-size: 1.5rem;
  font-weight: 300;
  color: ${({ theme }) => theme.text1};
`

export const Text = styled.span`
  font-size: 1.5rem;
  font-weight: 300;
  color: ${({ theme }) => theme.text1};
  text-align: justify;
`

export const NumberedTextBold = styled.div`
  display: flex;
  align-items: start;
  gap: 30px;
`

export const NumberBold = styled.span`
  font-size: 2rem;
  font-weight: 500;
  color: ${({ theme }) => theme.text1};
  margin: 0;
  padding: 0;
`

export const TextBold = styled.span`
  font-size: 2rem;
  font-weight: 500;
  color: ${({ theme }) => theme.text1};
  margin: 0;
  padding: 0;
`
