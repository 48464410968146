import { useRef, useEffect } from 'react'

/**
 * Changes title whenever a page changes
 * @dev: add your desired title in () as string
 */
export default function useTitle(title: string | null) {
  const documentDefined = typeof document !== 'undefined'
  const originalTitle = useRef(documentDefined ? document.title : '')

  useEffect(() => {
    if (!documentDefined) return
    if (document.title !== title + ' | Libex DeFi') document.title = title + ' | Libex DeFi'

    return () => {
      document.title =
        // eslint-disable-next-line
        originalTitle.current
    }
  }, [documentDefined, title])
}
