import { ChainId } from '@uniswap/sdk'

export const getMyLiquidity = async (address: string, network: number) => {
  let currentNetwork: string

  switch (network) {
    case ChainId.LIBEX_TESTNET:
      currentNetwork = 'testnet'
      break

    default:
      currentNetwork = 'testnet'
      break
  }

  const url = `${process.env.REACT_APP_BACKEND_URL}/myLiquidities/${address}?network=${currentNetwork}`
  console.log(url)
  const response = await fetch(url)

  if (!response.ok) {
    throw new Error(`Network request failed with status ${response.status}`)
  }

  const data = await response.json()
  return data
}
