import React, { useState } from 'react'
import {
  CircleIcon,
  H1,
  H2,
  ItemContainer,
  P1,
  PoolContainer,
  PoolDetailsContainer,
  PoolDetailsContainerLeft,
  PrecentageContainer,
  TextContainer
} from './style'
import { ArrowUp, ArrowDown } from 'react-feather'
import { getTokenLogoURL } from 'components/CurrencyLogo'
import { ButtonPrimary } from 'components/Button'
import { Link } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import { WETH } from '@uniswap/sdk'
import { useTranslation } from 'react-i18next'
// import { currencyId } from 'utils/currencyId'

interface PoolProps {
  pool: any
}

const PoolCardDetails = ({ pool }: PoolProps) => {
  const [showDetails, setShowDetails] = useState(false)
  const { chainId } = useWeb3React()
  const { t } = useTranslation()

  const toggleDetails = () => {
    setShowDetails(prev => !prev)
  }

  return (
    <React.Fragment>
      <PoolContainer onClick={toggleDetails}>
        <ItemContainer style={{ display: 'flex', flexDirection: 'row' }}>
          <CircleIcon style={{ position: 'relative' }}>
            <img src={getTokenLogoURL(pool?.token0?.id)} alt="" style={{ width: '24px', height: '24px' }} />
            <img src={getTokenLogoURL(pool?.token1?.id)} alt="" style={{ width: '24px', height: '24px' }} />
          </CircleIcon>
          <H1 style={{ minWidth: '100px' }}>
            {pool?.token0?.symbol} / {pool?.token1?.symbol}
          </H1>
        </ItemContainer>
        <ItemContainer mobileHidden={true}>
          <PrecentageContainer>
            <span>0.3%</span>
          </PrecentageContainer>
        </ItemContainer>
        <ItemContainer mobileHidden={true}>
          <TextContainer>
            <P1>
              {t('volumeToken')} / {pool?.token0?.symbol}
            </P1>
            {<H1>{parseFloat(pool?.volumeToken0).toFixed(2)}</H1>}
          </TextContainer>
        </ItemContainer>
        <ItemContainer mobileHidden={true}>
          <TextContainer>
            <P1>
              {t('volumeToken')} / {pool?.token1?.symbol}
            </P1>
            {<H1>{parseFloat(pool?.volumeToken1).toFixed(2)}</H1>}
          </TextContainer>
        </ItemContainer>
        <ItemContainer mobileHidden={true}>
          <TextContainer>
            <P1>{t('liquidityProvider')}</P1>
            <H1>{pool?.liquidityProviderCount}</H1>
          </TextContainer>
        </ItemContainer>
        <ItemContainer mobileHidden={true}>
          <TextContainer>
            <P1>{t('transactionCount')}</P1>
            <H1>{pool?.txCount}</H1>
          </TextContainer>
        </ItemContainer>
        <ItemContainer style={{ display: 'flex', justifyContent: 'end', paddingRight: '20px' }}>
          {showDetails ? <ArrowUp style={{ width: '20px' }} /> : <ArrowDown style={{ width: '20px' }} />}
        </ItemContainer>
      </PoolContainer>
      {showDetails && (
        <PoolDetailsContainer>
          <PoolDetailsContainerLeft>
            <H1>{t('hourlyVolume')}</H1>
            <H2>
              <P1 style={{ display: 'inline' }}>{pool?.token0?.symbol}:</P1>{' '}
              {parseFloat(pool?.hourlyVolumeToken0).toFixed(2)}
            </H2>
            <H2>
              <P1 style={{ display: 'inline' }}>{pool?.token1?.symbol}:</P1>{' '}
              {parseFloat(pool?.hourlyVolumeToken1).toFixed(2)}
            </H2>
          </PoolDetailsContainerLeft>
          <PoolDetailsContainerLeft>
            <H1>{t('dailyVolume')}</H1>
            <H2>
              <P1 style={{ display: 'inline' }}>{pool?.token0?.symbol}:</P1>{' '}
              {parseFloat(pool?.dailyVolumeToken0).toFixed(2)}
            </H2>
            <H2>
              <P1 style={{ display: 'inline' }}>{pool?.token1?.symbol}:</P1>{' '}
              {parseFloat(pool?.dailyVolumeToken1).toFixed(2)}
            </H2>
          </PoolDetailsContainerLeft>
          <PoolDetailsContainerLeft>
            <H1>{t('weeklyVolume')}</H1>
            <H2>
              <P1 style={{ display: 'inline' }}>{pool?.token0?.symbol}:</P1>{' '}
              {parseFloat(pool?.weeklyVolumeToken0).toFixed(2)}
            </H2>
            <H2>
              <P1 style={{ display: 'inline' }}>{pool?.token1?.symbol}:</P1>{' '}
              {parseFloat(pool?.weeklyVolumeToken1).toFixed(2)}
            </H2>
          </PoolDetailsContainerLeft>
          <PoolDetailsContainerLeft>
            <H1>{t('monthlyVolume')}</H1>
            <H2>
              <P1 style={{ display: 'inline' }}>{pool?.token0?.symbol}:</P1>{' '}
              {parseFloat(pool?.monthlyVolumeToken0).toFixed(2)}
            </H2>
            <H2>
              <P1 style={{ display: 'inline' }}>{pool?.token1?.symbol}:</P1>{' '}
              {parseFloat(pool?.monthlyVolumeToken1).toFixed(2)}
            </H2>
          </PoolDetailsContainerLeft>
          <PoolDetailsContainerLeft>
            <ButtonPrimary
              padding="8px"
              borderRadius="8px"
              as={Link}
              to={`/add/${
                /* @ts-ignore */
                pool?.token0?.id.toLowerCase() === WETH[chainId]?.address.toLowerCase() ? 'ETH' : pool?.token0?.id
                /* @ts-ignore */
              }/${pool?.token1?.id.toLowerCase() === WETH[chainId]?.address.toLowerCase() ? 'ETH' : pool?.token1?.id}`}
              width="100%"
            >
              {t('addLiquidity')}
            </ButtonPrimary>
            <ButtonPrimary
              padding="8px"
              borderRadius="8px"
              as={Link}
              to={`/remove/${
                /* @ts-ignore */
                pool?.token0?.id.toLowerCase() === WETH[chainId]?.address.toLowerCase() ? 'ETH' : pool?.token0?.id
                /* @ts-ignore */
              }/${pool?.token1?.id.toLowerCase() === WETH[chainId]?.address.toLowerCase() ? 'ETH' : pool?.token1?.id}`}
              width="100%"
            >
              {t('removeLiquidity')}
            </ButtonPrimary>
          </PoolDetailsContainerLeft>
        </PoolDetailsContainer>
      )}
    </React.Fragment>
  )
}

export default PoolCardDetails
