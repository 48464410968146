import React from 'react'
import styled from 'styled-components'

export const BodyWrapper = styled.div`
  position: relative;
  min-width: 350px;
  width: 100%;
  max-width: 420px;
  flex-grow: 1;
  flex-shrink: 1;
  background: ${({ theme }) => theme.bg1};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 30px;
  margin-top: 5rem;
  /* padding: 1rem; */
  ${({ theme }) => theme.mediaWidth.upToSmall`
  min-width: 350px;
  `}
  ${({ theme }) => theme.mediaWidth.mobileL`
  min-width: 300px;
  `}
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  return <BodyWrapper>{children}</BodyWrapper>
}
