import React, { useEffect, useState } from 'react'
import {
  BeeHoneypot,
  HeaderContainer,
  HeaderContainerWrapper,
  HeaderLeft,
  HeaderRight,
  MainCont,
  P2,
  PaginationContainer,
  SearchContainer,
  SortByTypeDropdown,
  SortByTypeMenuItem,
  SortByTypeWrapper,
  Title,
  UnderTitle,
  WrapperContainer
} from './style'
import beeHoneypotPic from '../../assets/images/home/beeHoneypot.png'
import { ArrowDown, ArrowLeft, ArrowRight, ArrowUp } from 'react-feather'
import PoolsList from 'components/PoolCardDetails/poolsList'
import { useQuery } from '@tanstack/react-query'
import { getMyPools, getPoolsLenght } from 'api/poolsList'
import { useWeb3React } from '@web3-react/core'
import { useTranslation } from 'react-i18next'

export const PoolListPage = () => {
  const { account, chainId } = useWeb3React()
  const { t } = useTranslation()

  const [sortByType, setSortByType] = useState('Sort by type')
  const [numberPoolsPerPage, setNumberPoolsPerPage] = useState('5')
  const [showDropdown, setShowDropdown] = useState(false)
  const [showDropDownForPages, setShowDropDownForPages] = useState(false)
  const [descSort, setDescSort] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)

  const options = ['liquidityProviderCount', 'txCount']
  const optionsForPoolsNumber = ['5', '10', '25']

  const { data: dataPoolsLenght } = useQuery(['poolsLenght', chainId], () => getPoolsLenght(chainId), {
    enabled: account ? true : false,
    refetchOnWindowFocus: false
  })
  const { data, isLoading } = useQuery(
    ['myPools', chainId, sortByType, descSort, numberPoolsPerPage, currentPage],
    () =>
      getMyPools(
        chainId,
        descSort ? 'asc' : 'desc',
        sortByType === 'Sort by type' ? 'liquidityProviderCount' : sortByType,
        currentPage,
        Number(numberPoolsPerPage)
      ),

    {
      enabled: account ? true : false && dataPoolsLenght?.data > 0,
      refetchOnWindowFocus: false
    }
  )

  const handleWrapperClick = () => {
    setShowDropdown(!showDropdown)
  }
  const handleShowDropDown = () => {
    setShowDropDownForPages(!showDropDownForPages)
  }

  const handleOptionClick = (option: string) => {
    setSortByType(option)
    setShowDropdown(false)
  }

  const handleOptionForPoolsClick = (option: string) => {
    setNumberPoolsPerPage(option)
    setShowDropDownForPages(false)
    setCurrentPage(1)
  }

  useEffect(() => {
    setTotalPages(Math.ceil(dataPoolsLenght?.data / Number(numberPoolsPerPage)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPoolsLenght?.data, numberPoolsPerPage])

  return (
    <MainCont>
      <WrapperContainer>
        <HeaderContainer
          style={{
            paddingTop: '50px'
          }}
        >
          <HeaderContainerWrapper>
            <HeaderLeft>
              <Title>{t('poolsList')}</Title>
              <UnderTitle>{t('poolsListEarn')}</UnderTitle>
            </HeaderLeft>
            <HeaderRight>
              <BeeHoneypot src={beeHoneypotPic} alt="BeeHoneypot" />
            </HeaderRight>
          </HeaderContainerWrapper>
        </HeaderContainer>
        <React.Fragment>
          {data?.data && (
            <SearchContainer>
              <SortByTypeWrapper onClick={handleWrapperClick}>
                <P2>{sortByType === 'txCount' ? t('txCount') : t('lpCount')}</P2>
                <ArrowDown style={{ width: '20px' }} />
                {showDropdown && (
                  <SortByTypeDropdown>
                    {options.map((option, index) => (
                      <SortByTypeMenuItem key={index} onClick={() => handleOptionClick(option)}>
                        {option === 'liquidityProviderCount' ? t('lpCount') : t('txCount')}
                      </SortByTypeMenuItem>
                    ))}
                  </SortByTypeDropdown>
                )}
              </SortByTypeWrapper>
              {/* FOR PEGES */}
              <SortByTypeWrapper onClick={handleShowDropDown}>
                <P2>
                  {numberPoolsPerPage} {t('poolsPerPage')}
                </P2>
                <ArrowDown style={{ width: '20px' }} />
                {showDropDownForPages && (
                  <SortByTypeDropdown>
                    {optionsForPoolsNumber.map((option, index) => (
                      <SortByTypeMenuItem key={index} onClick={() => handleOptionForPoolsClick(option)}>
                        {option}
                      </SortByTypeMenuItem>
                    ))}
                  </SortByTypeDropdown>
                )}
              </SortByTypeWrapper>
              {descSort ? (
                <ArrowDown
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setDescSort(false)
                  }}
                />
              ) : (
                <ArrowUp
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setDescSort(true)
                  }}
                />
              )}
            </SearchContainer>
          )}
          <PoolsList isLoading={isLoading} poolData={data?.data} />
          {data?.data && (
            <PaginationContainer>
              <ArrowLeft
                onClick={() => {
                  if (currentPage === 1) return
                  setCurrentPage(currentPage - 1)
                }}
                style={{ width: '20px', cursor: 'pointer' }}
              />{' '}
              {currentPage} {t('of')} {totalPages}
              <ArrowRight
                onClick={() => {
                  if (currentPage === totalPages) return
                  setCurrentPage(currentPage + 1)
                }}
                style={{ width: '20px', cursor: 'pointer' }}
              />
            </PaginationContainer>
          )}
        </React.Fragment>
      </WrapperContainer>
    </MainCont>
  )
}
