import { CHART_DATA_API } from './endpoints'
import axios from 'axios'

export const instanceProxy = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    'Access-Control-Allow-Origin': '*'
  }
})

export const getChartData = async (arg: {
  pairContractAddress: string
  timePeriod: '1d' | '1w' | '1m' | '3m' | '6m' | '1y' | 'all'
  network: 'mainnet' | 'testnet'
}) => {
  if (arg?.pairContractAddress === undefined || '') {
    return
  } else {
    const { data } = await instanceProxy.get(`${CHART_DATA_API}/${arg?.pairContractAddress}`, {
      params: {
        timeframe: arg?.timePeriod,
        network: arg?.network
      }
    })
    return data
  }
}
