import React from 'react'
import styled from 'styled-components'

import { useURLWarningToggle, useURLWarningVisible } from '../../state/user/hooks'
import { isMobile } from 'react-device-detect'
import { PAGE_URL } from '../../constants/index'
import { useTranslation } from 'react-i18next'
import { X } from 'react-feather'

interface Props {
  active: boolean
}

export const Container = styled.div<Props>`
  width: 100%;
  display: ${({ active }) => (active ? `flex` : `none`)};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 4.5rem;
  background-image: linear-gradient(
    to right,
    ${({ theme }) => theme.primary1} 50%,
    ${({ theme }) => theme.primary2} 100%
  );
  box-shadow: 0px 0px 5px black inset;
  ${({ theme }) => theme.mediaWidth.upToSmall`
           height: 5.1rem;
         `};
`

export const Alert = styled.div`
  max-width: 50%;
  height: 70%;
  padding: 5px;
  padding-left: 7px;
  padding-right: 7px;
  color: #d67e0a;
  background-color: #00000048;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 2;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 82%;
    padding: 9px;
  `};
`

export const H1 = styled.h1`
  margin: 0;
  font-weight: 600;
  font-size: 15px;
`

export const P1 = styled.span`
  margin: 0;
  font-size: 16px;
  color: white;
  ${({ theme }) => theme.mediaWidth.laptopL`
    font-size: 14px;
  `};
`

export const P2 = styled.a`
  margin: 0;
  font-size: 16px;
  color: #d67e0a;
  ${({ theme }) => theme.mediaWidth.laptopL`
    font-size: 14px;
  `};
`

export const CloseWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 15px;
  color: white;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  ${({ theme }) => theme.mediaWidth.mobileL`
    right: 5px;
  `};
`
export const CloseIcon = styled(X)`
  color: ${props => props.theme.text7};
  :hover {
    cursor: pointer;
  }
`

export default function URLWarning() {
  const { t } = useTranslation()
  const toggleURLWarning = useURLWarningToggle()
  const showURLWarning = useURLWarningVisible()
  return isMobile ? (
    <Container active={showURLWarning}>
      <Alert>
        <H1>{t('phishingWarning')} </H1>
        <P1>
          {t(`${t('phishingNotice1')} `)}
          <P2>{PAGE_URL}</P2>
          {t(` -${t('phishingNotice2')}`)}{' '}
        </P1>
      </Alert>
      <CloseWrapper onClick={toggleURLWarning}>
        <CloseIcon />
      </CloseWrapper>
    </Container>
  ) : window.location.hostname === 'localhost' /* configurable: change localhost for your host name */ ? (
    <Container active={showURLWarning}>
      <Alert>
        <H1>
          {t('phishingWarning')} {` `}
          <P1>
            {t(`${t('phishingNotice1')} `)}
            <P2 href={PAGE_URL} target="_blank">
              {PAGE_URL}
            </P2>
            {t(` -${t('phishingNotice2')}`)}{' '}
          </P1>
        </H1>
      </Alert>
      <CloseWrapper onClick={toggleURLWarning}>
        <CloseIcon />
      </CloseWrapper>
    </Container>
  ) : null
}
