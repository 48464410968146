import { transparentize } from 'polished'
import React from 'react'
import { AlertTriangle } from 'react-feather'
import styled, { css } from 'styled-components'
import { Text } from 'rebass'
import { AutoColumn } from '../Column'

export const Wrapper = styled.div`
  position: relative;
  padding: 1rem;
`

export const ArrowWrapper = styled.div<{ clickable: boolean }>`
  padding: 9px;
  background-color: ${({ theme }) => theme.primary1};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ clickable }) =>
    clickable
      ? css`
          :hover {
            cursor: pointer;
            opacity: 0.8;
          }
        `
      : null}
`

export const SectionBreak = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.bg3};
`

export const BottomGrouping = styled.div`
  margin-top: 1rem;
`

export const ErrorText = styled(Text)<{ severity?: 0 | 1 | 2 | 3 | 4 }>`
  color: ${({ theme, severity }) =>
    severity === 3 || severity === 4
      ? theme.red1
      : severity === 2
      ? theme.yellow2
      : severity === 1
      ? theme.text1
      : theme.green1};
`

export const StyledBalanceMaxMini = styled.button`
  height: 22px;
  width: 22px;
  background-color: ${({ theme }) => theme.bg2};
  border: none;
  border-radius: 50%;
  padding: 0.2rem;
  font-size: 0.875rem;
  font-weight: 400;
  margin-left: 0.4rem;
  cursor: pointer;
  color: ${({ theme }) => theme.text2};
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;

  :hover {
    background-color: ${({ theme }) => theme.bg3};
  }
  :focus {
    background-color: ${({ theme }) => theme.bg3};
    outline: none;
  }
`

export const TruncatedText = styled(Text)`
  text-overflow: ellipsis;
  width: 220px;
  overflow: hidden;
`

// styles
export const Dots = styled.span`
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: '.';
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: '.';
    }
    33% {
      content: '..';
    }
    66% {
      content: '...';
    }
  }
`

const SwapCallbackErrorInner = styled.div`
  background-color: ${({ theme }) => transparentize(0.9, theme.red1)};
  border-radius: 1rem;
  display: flex;
  align-items: center;
  font-size: 0.825rem;
  width: 100%;
  padding: 3rem 1.25rem 1rem 1rem;
  margin-top: -2rem;
  color: ${({ theme }) => theme.red1};
  z-index: -1;
  p {
    padding: 0;
    margin: 0;
    font-weight: 500;
  }
`

const SwapCallbackErrorInnerAlertTriangle = styled.div`
  background-color: ${({ theme }) => transparentize(0.9, theme.red1)};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  border-radius: 12px;
  min-width: 48px;
  height: 48px;
`

export const WrapperContainer = styled.div`
  display: flex;
  min-width: 290px;
  max-width: 90vw;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 838px) {
    flex-direction: column-reverse;
  }
`

//above chart data styles
export const HeadingLabels = styled.div<{ isShowChart: boolean }>`
  max-width: 100%;
  min-height: 65px;
  margin-bottom: 2rem;
  display: ${({ isShowChart }) => (isShowChart ? 'flex' : 'none')};
  justify-content: space-between;
  @media screen and (max-width: 1231px) {
    flex-direction: column;
    margin-bottom: 2rem;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    align-items: center;
    gap: 10px;
    height: 100%;
  `}
`

export const HeadingLabelLeft = styled.div`
  max-width: 50%;
  height: 100%;
  display: flex;
  gap: 16px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `}
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `}
`

export const BigText = styled.div`
  font-size: 55px;
  font-weight: 600;
  line-height: 45px;
  color: ${({ theme }) => theme.text1};
  display: flex;
  align-items: end;
`

export const PriceChangeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  @media screen and (max-width: 986px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 3px;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
  `}
`

export const SmallTextWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
`

export const TextUp = styled.div`
  height: 50%;
  display: flex;
  align-items: end;
  color: ${({ theme }) => theme.text7};
`

export const TextDown = styled.div`
  height: 50%;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
  align-items: end;
`

export const TokensText = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 10px;
  color: ${({ theme }) => theme.text7};
`

export const GreenText = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 10px;
  width: 8rem;
  color: ${({ theme }) => (theme.currentTheme === 'light' ? '#2A51E1' : '#16D59C')};
`

export const HeadingLabelRight = styled.div`
  max-width: 50%;
  height: 100%;
  display: flex;
  align-items: end;
  justify-content: flex-end;
  gap: 16px;
  @media screen and (max-width: 1231px) {
    margin-top: 0.4rem;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `}
`

export const TabMenuTime = styled.div`
  width: 250px;
  height: 40px;
  box-shadow: 0px 9px 30px rgba(0, 0, 0, 0.08);
  border-radius: 32px;
  background-color: ${({ theme }) => theme.primary2};
  display: flex;
`

export const Tab = styled.div<{ isActive: boolean }>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 32px;
  color: white;
  cursor: pointer;
  background-color: ${props => (props.isActive ? props.theme.primary6 : 'transparent')};
  color: ${props => (props.isActive ? '#FFFFFF' : props.theme.text7)};
`

export const ChartWrapper = styled.div<{
  isExtendChart: boolean
  isShowChart: boolean
}>`
  max-width: 100%;
  min-height: ${({ isExtendChart }) => (isExtendChart ? '700px' : '400px')};
  display: ${({ isShowChart }) => (isShowChart ? 'flex' : 'none')};
  background-color: ${({ theme }) => theme.bg2};
  box-shadow: 0px 33px 40px rgba(0, 0, 0, 0.03);
  border-radius: 32px;
  box-shadow: 0px 16px 30px rgba(42, 81, 225, 0.1);
  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-width: 100%;
  `}
`

export const DivDiv = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 350px;
  width: 70%;
  min-height: 500px;
  height: 100%;
  padding-right: 20px;
  flex-shrink: 0;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-width: 100%;
    width: 100%;
    padding-right: 0px;
  `}
  @media screen and (max-width: 1080px) {
    width: 65%;
  }
  @media screen and (max-width: 885px) {
    width: 55%;
  }
`

export function SwapCallbackError({ error }: { error: string }) {
  return (
    <SwapCallbackErrorInner>
      <SwapCallbackErrorInnerAlertTriangle>
        <AlertTriangle size={24} />
      </SwapCallbackErrorInnerAlertTriangle>
      <p>{error}</p>
    </SwapCallbackErrorInner>
  )
}

export const SwapShowAcceptChanges = styled(AutoColumn)`
  background-color: ${({ theme }) => transparentize(0.9, theme.primary1)};
  color: ${({ theme }) => theme.primary1};
  padding: 0.5rem;
  border-radius: 12px;
  margin-top: 8px;
`
export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.bg2};
`
